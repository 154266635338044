import React from "react";
import { Fragment } from "react";

const BlogDetail = () => {
  return (
  <Fragment>
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div class="bread-crumb">
              <a href="/" title="Back to the frontpage">Home<i class="fa fa-angle-right" aria-hidden="true"></i></a>
              <a href="/blogs/news">News <i class="fa fa-angle-right" aria-hidden="true"></i></a>
              <strong>A Lesson In Wines</strong>
            </div>
          </div>
          <div className="">
            <img src="/assets/images/banner-img-blog.png" />
          </div>
        </div>
      </div>
    </section>

    <section className="blog-detail-list">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div>
              <h3>Where Can I Get Some?</h3>
              <p>Etiam risus diam, porttitor vitae ultrices quis, dapibus id dolor.Morbi venenatis lacinia rhoncus.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean non enim ut enim fringilla adipiscing id in lorem. Quisque aliquet neque vitae lectus tempus consectetur. Aliquam erat volutpat. Nunc eu nibh nulla, id cursus arcu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nam at velit nisl. Aenean vitae est nisl. Cras molestie molestie nisl vel imperdiet. Donec vel mi sem.</p>
              <blockquote>
                <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id estlaborum. Sed ut perspiciatis<span>&nbsp;</span><span>ROBERT SMITH</span></p>
              </blockquote>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="blog">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="title2 text-center">
                <h3>our Blogs</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-wrap">
                <div className="blog-picture position-relative">
                  <div className="date">
                    <span className="day"> 24</span>
                    <span className="month">Jan</span>
                  </div>
                  <a href="#">
                    <img src="./assets/images/blog.png" alt="blog" />
                  </a>
                </div>
                <div className="info-blog text-center">
                  <a className="blog-cate" href="">
                    News
                  </a>
                  <h4 className="title-blog">
                    <a href="">A Lesson In Wines</a>
                  </h4>
                  <p className="content mb-0">
                    Where can I get some? Etiam risus diam, porttitor vitae
                    ultrices quis, dapibus id dolor.Morbi venenatis lacinia
                    rhoncus.Lorem ipsum dolor sit amet,...
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-wrap">
                <div className="blog-picture position-relative">
                  <div className="date">
                    <span className="day"> 24</span>
                    <span className="month">Jan</span>
                  </div>
                  <a href="#">
                    <img src="./assets/images/blog.png" alt="blog" />
                  </a>
                </div>
                <div className="info-blog text-center">
                  <a className="blog-cate" href="">
                    News
                  </a>
                  <h4 className="title-blog">
                    <a href="">A Lesson In Wines</a>
                  </h4>
                  <p className="content mb-0">
                    Where can I get some? Etiam risus diam, porttitor vitae
                    ultrices quis, dapibus id dolor.Morbi venenatis lacinia
                    rhoncus.Lorem ipsum dolor sit amet,...
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-wrap">
                <div className="blog-picture position-relative">
                  <div className="date">
                    <span className="day"> 24</span>
                    <span className="month">Jan</span>
                  </div>
                  <a href="#">
                    <img src="./assets/images/blog.png" alt="blog" />
                  </a>
                </div>
                <div className="info-blog text-center">
                  <a className="blog-cate" href="">
                    News
                  </a>
                  <h4 className="title-blog">
                    <a href="">A Lesson In Wines</a>
                  </h4>
                  <p className="content mb-0">
                    Where can I get some? Etiam risus diam, porttitor vitae
                    ultrices quis, dapibus id dolor.Morbi venenatis lacinia
                    rhoncus.Lorem ipsum dolor sit amet,...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
 
  </Fragment>
  
  );
};

export default BlogDetail;
