import { useState } from "react";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { checkWebIsVerify, ConfirmationOtpCode, InsertAccount, phoneNumberAuth } from "../../Firebase";
import { useDispatch } from "react-redux";
import { isLogin, user } from "../../Redux/Authentication/Login";
import { User, UserAccount } from "../../types";
import CountryListt from "./countryList";


const MainModal = (props: any) => {
  const { open, close, type } = props;
  const dispatch = useDispatch()
  const [tType, setType] = useState(type);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [otp, setOtp] = useState('');
  const [countryCode, setcountryCode] = useState('+1');
  
  
  const closeModal = () => {
    close();
    setType('login');
  }

  const SigninAccount = (userinfo: User) => {
    const userAccount = {
      Active: true,
      Uid: userinfo.uid,
      Type: 'User',
      Name: name,
      Last: userinfo.lastSigninTime,
      Mobileno: userinfo.phoneNumber,
      Date: userinfo.createTime,
    }
    dispatch(user(userinfo as User))
    InsertAccount(userAccount as UserAccount, () => {})
    dispatch(isLogin(true))
    closeModal();
  }

  return (
    <Modal
      open={open}
      onClose={closeModal}
      center
      styles={{modal: {width: '40%'}}}
    >

      {tType === "login" && (
        <div id="sign-in" className="login-setup-section">
          <div className="text-center logo">
            <img src="./assets/images/Ezcape_Logo.png" alt="" />
          </div>
           <h3 className="request-otp-header">
            Please verify your mobile number
          </h3>
          <div className="form-group login-label">
            <label htmlFor="inputnumber">Name</label>
            <input
              type="text"
              className="form-control input-edit"
              placeholder="Enter Your Name"
              value={name}
              onChange={e => setName(e.target.value)}
              id="number"
            />
          </div>
          <div className="form-group login-label">
            
            <label htmlFor="inputnumber">mobile number</label>
            <div className="row col-lg-12">
              <div className="col-lg-4">
              <CountryListt select={(country: any) => {
                setcountryCode(country)
                
              }}/>
              </div>
              <div  className="col-lg-8">
              <input
                type="text"
                className="form-control input-edit"
                placeholder="Enter mobile number"
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
                id="number"
                style={{marginTop: '5px'}}
              />
              </div>
            </div>
          </div>
          <button
            type="button"
            className="border-0 cmn-btn w-100 btn-default btn-lg btn-block request-otp"
            id="request-otp"
            onClick={() => checkWebIsVerify(verify => {
              phoneNumberAuth(verify, countryCode+phoneNumber, () => {
                setType('otp');
              })
            })}
          >
            Get OTP
          </button>
          
        </div>
      )}

      {tType === "otp" && (
        <div className="container new-login-area p-0">
          <div id="verify-otp" className="login-setup-section">
            <div className="text-center logo">
              <img src="./assets/images/Ezcape_Logo.png" alt="" />
            </div>
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
            <h3 className="request-otp-header">Verify OTP</h3>
            <div className="form-group login-label">
              <label htmlFor="inputnumber">One Time Password</label>
              <input
                type="text"
                className="form-control input-edit"
                placeholder="Enter OTP"
                value={otp}
                onChange={e => setOtp(e.target.value)}
                id="number"
              />
              <label className="pull-right resend-otp">Resend otp</label>
            </div>
            <button
              type="button"
              className="border-0 cmn-btn w-100 btn-default btn-lg btn-block request-otp "
              onClick={() => ConfirmationOtpCode(otp, (userinfo) => {
                SigninAccount(userinfo as User);
              })}
            >
              Verify
            </button>
          </div>
          
        </div>
      )}
      
    </Modal>
  );
};

export default MainModal;
