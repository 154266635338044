import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { GetCartProduct, getDefaultAddress, ReadCategory, ReadProduct } from "../../Firebase";
import { DefaultAddress, setopenLoginModule } from "../../Redux/Authentication/Login";
import { addtoCart, setCategorys, setProducts } from "../../Redux/Products/ProductManage";
import { RootState } from "../../Redux/Store";
import { Address, User } from "../../types";
import MainModal from "../Common/MainModal";
import SearchBar from "../Common/SearchBar";
import ShoppingCart from "../Common/ShoppingCart";
import WishList from "../Common/wishList";

const Header = () => {
  const dispatch = useDispatch()
  const navigation = useNavigate();
  const userinfo: User = useSelector((state: RootState) => state.login.user);
  const openLoginModule: Boolean = useSelector((state: RootState) => state.login.openLoginModule);
  const [openModal, setOpenModal] = useState(openLoginModule);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [openShoppingCart, setOpenShoppingCart] = useState(false);
  const [openWishListCart, setOpenWishListCart] = useState(false);
  const [showFeatSubMenu, setShowFeatSubMenu] = useState(false);
  const [sideMenuComp,setSideMenuComp] = useState(false);
  const [rightMenuOpen , setRightMenuOpen] = useState(false); 
  const [offer , setOffer] = useState(true); 
  const [type, setType] = useState<string>();

  useEffect(() => {
    setOpenModal(openLoginModule);
  }, [openLoginModule]);

  const openModalHandler = () => {
    if(Object.keys(userinfo).length > 0){
      navigation('Profile')

    }else {
      setOpenModal(true);
      setType('login');
    }
  };

  const closeModalHandler = () => {
    setOpenModal(false);
    dispatch(setopenLoginModule(false));
  };

  const SearchModalHandler = () => {
    setOpenSearchModal(true);
  };

  const CloseSearchHandler = () => {
    setOpenSearchModal(false);
  };

  const shoppingCartHandler = () => {
    setOpenShoppingCart(true);
  };

  const closeShoppingHandler = () => {
    setOpenShoppingCart(false);
  };


  const wishListHandler = () => {
    setOpenWishListCart(true);
  };

  const closeWishListHandler = () => {
    setOpenWishListCart(false);
  };


  const sideMenuHandler = () => {
    setSideMenuComp(true)
  }

  const closeSideMenu = () => {
    setSideMenuComp(false);
    setRightMenuOpen(false);
  }


  useEffect(() => {
    
    
    
    ReadProduct(products => {
      dispatch(setProducts(products))
    })
    ReadCategory(categorys => {
      dispatch(setCategorys(categorys))
    })
    GetCartProduct(userinfo.uid, data => {
      dispatch(addtoCart(data));
    })
    getDefaultAddress(userinfo.uid, data => {
      dispatch(DefaultAddress(data as Address));
    })
  }, []);

  return (
    <React.Fragment>
      <header>
      {
          offer ? (
<div className="p-0" style={{backgroundColor: 'black', color:'white', fontSize: 20, zIndex: 10, top:0, width: '100%', height: '50px', marginTop: '-1px'}}>
          
          <p style={{display: "flex", justifyContent: "center", justifyItems:'center', paddingTop:'0.5%', alignItems: "center"}}>
            🍾 Free shipping when ordering 4 or more bottles🍾 USE CODE: FREESHIP
          <a href="./products" style={{backgroundColor:'white', borderRadius: 30, color:'black', fontSize: 10, paddingLeft: '10px', paddingRight: '10px', paddingTop: '5px', paddingBottom: '5px', marginLeft: '10px'}}>
            Shop now
          </a>
          <a href="javascript:void(0)"  className="mini-cart-undo close-mini-cart" onClick={() => setOffer(false)}>
            <i className="fas fa-times" style={{color:'white', marginLeft: '10px'}}></i>
          </a>
          </p>
          
</div>
          ) : (<></>)
        }
        <div className="col-lg-12 p-0">
          <nav className="navbar-expand-lg">

            <div className="container-fluid">
              <span className="iconmenu" onClick={sideMenuHandler}>
                <span></span>
                <span></span>
                <span></span>
              </span>
              <a className="navbar-brand" href="./">
                <img src="./assets/images/Ezcape_Logo.png" alt="log"/>
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsibleNavbar"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse justify-content-center"
                id="collapsibleNavbar"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link" href="./">
                      <p>Home</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to={"/products"}>
                      Shop
                    </Link>
                  </li>
                  {/*
                  <li className="nav-item" 
                    onMouseEnter={(e) => setShowFeatSubMenu(true)}
                    onMouseLeave={(e) => setShowFeatSubMenu(false)}>
                    <a className="nav-link" href="#">
                      Feature
                    </a>
                    <div
                      className={
                        showFeatSubMenu
                          ? "dropdown-menu list-woman shop is-active d-block"
                          : "dropdown-menu list-woman shop"
                      }
                    >
                      <div className="row">
                        <div className="col-lg-4">
                          <ul className="menu-list m-0">
                            <li>
                              <a href="#">DEMO LAYOUTS</a>
                            </li>
                            <li>
                              <a href="#">Full Screen</a>
                            </li>
                            <li>
                              <a href="#">Heading Background</a>
                            </li>
                            <li>
                              <a href="#">Simple</a>
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-4">
                          <ul className="menu-list m-0">
                            <li>
                              <a href="#">PRE-BUILD PAGES</a>
                            </li>
                            <li>
                              <a href="#">About Us #1</a>
                            </li>
                            <li>
                              <a href="#">About Us #2</a>
                            </li>
                            <li>
                              <a href="#">About Us #3</a>
                            </li>
                            <li>
                              <a href="#">About Us #4</a>
                            </li>
                            <li>
                              <a href="#">contact us #1</a>
                            </li>
                            <li>
                              <a href="#">contact us #2</a>
                            </li>
                            <li>
                              <a href="#">contact us #3</a>
                            </li>
                            <li>
                              <a href="#">FAQs</a>
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-4">
                          <ul className="menu-list m-0">
                            <li>
                              <a href="#">ECOMERCE</a>
                            </li>
                            <li>
                              <a href="#">cart</a>
                            </li>
                            <li>
                              <a href="#">404 page</a>
                            </li>
                            <li>
                              <a href="#">my account</a>
                            </li>
                            <li>
                              <a href="#">login / register</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  */}
                  <li className="nav-item">
                    <a className="nav-link" href="/about">
                      About us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/blog">
                      Blogs
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/contact">
                      Contact us
                    </a>
                  </li>
                 
                </ul>
              </div>
              <div>
                <a href="#" onClick={SearchModalHandler}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 400 400"
                    height="400"
                    width="400"
                    id="svg2"
                    version="1.1"
                  >
                    <defs id="defs6"></defs>
                    <g
                      transform="matrix(1.3333333,0,0,-1.3333333,0,400)"
                      id="g10"
                    >
                      <g transform="scale(0.1)" id="g12">
                        <path
                          id="path14"
                          d="m 1312.7,795.5 c -472.7,0 -857.204,384.3 -857.204,856.7 0,472.7 384.504,857.2 857.204,857.2 472.7,0 857.3,-384.5 857.3,-857.2 0,-472.4 -384.6,-856.7 -857.3,-856.7 z M 2783.9,352.699 2172.7,963.898 c 155.8,194.702 241.5,438.602 241.5,688.302 0,607.3 -494.1,1101.4 -1101.5,1101.4 -607.302,0 -1101.399,-494.1 -1101.399,-1101.4 0,-607.4 494.097,-1101.501 1101.399,-1101.501 249.8,0 493.5,85.5 687.7,241 L 2611.7,181 c 23,-23 53.6,-35.699 86.1,-35.699 32.4,0 63,12.699 86,35.699 23.1,22.801 35.8,53.301 35.8,85.898 0,32.602 -12.7,63 -35.7,85.801"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </a>
                
                <a href="#" onClick={wishListHandler}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 400 400"
                    height="400"
                    width="400"
                    id="svg2"
                    version="1.1"
                  >
                    <defs id="defs6"></defs>
                    <g
                      transform="matrix(1.3333333,0,0,-1.3333333,0,400)"
                      id="g10"
                    >
                      <g transform="scale(0.1)" id="g12">
                        <path
                          id="path14"
                          d="m 903,2424.4 c 157.9,0 306.4,-61.5 418.1,-173.1 l 134.8,-134.9 c 20.7,-20.6 48.1,-32 77.1,-32 29,0 56.4,11.4 77,32 l 133.7,133.7 c 111.7,111.6 259.9,173.1 417.5,173.1 156.91,0 305,-61.3 416.8,-172.5 111.2,-111.3 172.5,-259.5 172.5,-417.5 0.6,-157.3 -60.69,-305.5 -172.5,-417.4 L 1531.5,373.5 487.402,1417.6 c -111.601,111.7 -173.105,259.9 -173.105,417.5 0,158.1 61.199,306.1 172.5,416.8 111.308,111.2 259.101,172.5 416.203,172.5 z m 1829.7,-19.6 c 0,0 0,0 -0.1,0 -152.4,152.4 -355.1,236.3 -570.9,236.3 -215.7,0 -418.7,-84.1 -571.5,-236.9 l -56.9,-57 -58.2,58.2 c -153.1,153.1 -356.3,237.5 -572.1,237.5 -215.305,0 -417.902,-83.9 -570.305,-236.3 -153,-153 -236.8942,-356 -236.2966,-571.5 0,-215 84.4026,-417.8 237.4966,-571 L 1454.7,143.301 c 20.5,-20.403 48.41,-32.199 76.8,-32.199 28.7,0 56.7,11.5 76.7,31.597 L 2731.5,1261.8 c 152.7,152.7 236.8,355.7 236.8,571.4 0.7,216 -83,419 -235.6,571.6"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </a>
                <a 
                  href="#" 
                  className="cart call-minicart" 
                  onClick={shoppingCartHandler}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 297.78668 398.66666"
                    height="398.66666"
                    width="297.78668"
                    id="svg2"
                    version="1.1"
                  >
                    <defs id="defs6"></defs>
                    <g
                      transform="matrix(1.3333333,0,0,-1.3333333,0,398.66667)"
                      id="g10"
                    >
                      <g transform="scale(0.1)" id="g12">
                        <path
                          id="path14"
                          d="M 2233.36,2432.71 H 0 V 0 h 2233.36 v 2432.71 z m -220,-220 V 220 H 220.004 V 2212.71 H 2021.36"
                        ></path>
                        <path
                          xmlns="http://www.w3.org/2000/svg"
                          id="path16"
                          d="m 1116.68,2990 v 0 C 755.461,2990 462.637,2697.18 462.637,2335.96 V 2216.92 H 1770.71 v 119.04 c 0,361.22 -292.82,654.04 -654.03,654.04 z m 0,-220 c 204.58,0 376.55,-142.29 422.19,-333.08 H 694.492 C 740.117,2627.71 912.102,2770 1116.68,2770"
                        ></path>
                        <path
                          xmlns="http://www.w3.org/2000/svg"
                          id="path18"
                          d="M 1554.82,1888.17 H 678.543 v 169.54 h 876.277 v -169.54"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </a>
                <a href="#" onClick={openModalHandler}>
                  {
                     JSON.stringify(userinfo)!== '{}' ? (
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 400 400"
                    height="400"
                    width="400"
                    id="svg2"
                    version="1.1"
                  >
                    <defs id="defs6"></defs>
                    <g
                      transform="matrix(1.3333333,0,0,-1.3333333,0,400)"
                      id="g10"
                    >
                      <g transform="scale(0.1)" id="g12">
                        <path
                          id="path14"
                          d="m 1506.87,2587.11 c -225.04,0 -408.14,-183.08 -408.14,-408.11 0,-225.06 183.1,-408.13 408.14,-408.13 225.02,0 408.13,183.07 408.13,408.13 0,225.03 -183.11,408.11 -408.13,408.11 z m 0,-1038.56 c -347.64,0 -630.432,282.79 -630.432,630.45 0,347.63 282.792,630.43 630.432,630.43 347.63,0 630.42,-282.8 630.42,-630.43 0,-347.66 -282.79,-630.45 -630.42,-630.45 v 0"
                        ></path>
                        <path
                          id="path16"
                          d="M 399.648,361.789 H 2614.07 c -25.06,261.531 -139.49,503.461 -327.47,689.831 -124.25,123.14 -300.78,193.96 -483.86,193.96 h -591.76 c -183.61,0 -359.601,-70.82 -483.863,-193.96 C 539.148,865.25 424.719,623.32 399.648,361.789 Z M 2730.69,139.461 H 283.035 c -61.558,0 -111.16,49.59 -111.16,111.16 0,363.438 141.68,704 398.32,959.019 165.657,164.55 399.414,258.82 640.785,258.82 h 591.76 c 241.94,0 475.14,-94.27 640.8,-258.82 256.63,-255.019 398.31,-595.581 398.31,-959.019 0,-61.57 -49.59,-111.16 -111.16,-111.16 v 0"
                        ></path>
                      </g>
                    </g>
                  </svg>
                     ) :
                    (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    height="400"
                    width="400"
                    id="svg2"
                    version="1.1"
                  >
                    <g>
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path d="M10 11V8l5 4-5 4v-3H1v-2h9zm-7.542 4h2.124A8.003 8.003 0 0 0 20 12 8 8 0 0 0 4.582 9H2.458C3.732 4.943 7.522 2 12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10c-4.478 0-8.268-2.943-9.542-7z" />
                    </g>
                  </svg> 
                    )
                  }
                  
                </a>
              </div>
            </div>
          </nav>
        </div>


        
      </header>
      <div className={sideMenuComp ? "sideMenu active" :"sideMenu"} >
          <div>
            <div className="side-logo">
              <img style={{width:"100px"}} src="./assets/images/Ezcape_Logo.png" alt="log"/>
            </div>
          </div>        
          <ul className="side-main-menu">
                <li>
                  <a href="/home">Home</a>
                </li>
                <li>
                  <a href="/products">Shop</a>
                </li>
                {/* <li onClick={()=>setRightMenuOpen(true)}>
                  <a>Feature </a>
                </li> */}
                <li>
                  <a href="/about">About US</a>
                </li>
                <li>
                  <a href="/blog">Blog</a>
                </li>
                <li>
                  <a href="/contact">Contact US</a>
                </li>
                
          </ul>    

          <div className= {rightMenuOpen ? "right-menu open":"right-menu"}>
          <div className="title" onClick={()=>setRightMenuOpen(false)}>
                  Featured
          </div>       
          <ul>
             
              <li className="submenu-main">
                  <span>animate Demos</span>
              </li>
                <li>
                  <a href="#">Quickview-popup</a>
                </li>
                <li>
                  <a href="#">Quickview-popup</a>
                </li>
                <li>
                  <a href="#">Quickview-popup</a>
                </li>
                <li>
                  <a href="#">Quickview-popup</a>
                </li>
          </ul>

          <ul>
             
              <li className="submenu-main">
                  <span>animate Demos</span>
              </li>
                <li>
                  <a href="#">Quickview-popup</a>
                </li>
                <li>
                  <a href="#">Quickview-popup</a>
                </li>
                <li>
                  <a href="#">Quickview-popup</a>
                </li>
                <li>
                  <a href="#">Quickview-popup</a>
                </li>
          </ul>
          </div>

          <div onClick={closeSideMenu}  className="close-mobile-menu">
                  <span>close</span>
          </div>    
      </div>
      <div onClick={closeSideMenu} className={sideMenuComp ?"menuCover active" : "menuCover"}>

      </div>

      
      <SearchBar open={openSearchModal} close={() => CloseSearchHandler()} />
      <WishList open={openWishListCart} close={() => closeWishListHandler()} />

      <ShoppingCart
        open={openShoppingCart}
        close={() => closeShoppingHandler()}
      />

      <MainModal
        open={openModal}
        close={() => closeModalHandler()}
        type={'login'}
      />
    </React.Fragment>
  );
};

export default Header;
