import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Fragment } from "react";
import Slider from "react-slick";
import ProductItem from "../../Component/Common/Product";
import { Category, Product } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store";
import { setSelectProduct } from "../../Redux/Products/ProductManage";
import Filter from "../../Component/Common/Filter";




const ProductsList = () => {
  const dispatch = useDispatch();
  const products: Array<Product> = useSelector((state: RootState) => state.product.products);
  const categories: Array<Category> = useSelector((state: RootState) => state.product.categorys); 
  const [filterProduct, setFilterProduct] = useState<Array<Product>>(Array<Product>());
  const [filterShow, setFilterShow] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [price, setPrice] = useState('all');
  const [color, setColor] = useState('all');
  const [cat, setCat] = useState('all');

  var bestSeller = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrow: false,
    navigtion: false,
  };

  useEffect(() => {
    setFilterProduct(products);
  }, [products])

  useEffect(() => {
    if(price !== "all"){
      let lowPrice = Number(price.split('-')[0])
      let highPrice = Number(price.split('-')[1])
      const fp = products.filter(item => {
        if (Number(item.Original_price) <= highPrice) {
          if (Number(item.Original_price) >= lowPrice) {
            return item;
          } 
        }  
      })
      setFilterProduct(JSON.parse(JSON.stringify(fp)) as Array<Product>);
    }
    else {
      setFilterProduct(products);
    }
  }, [price])

  useEffect(() => {
    if(cat !== 'all'){
      const fp = products.filter(item => {
        if(item.Category_id === cat){
          return item;
        }
      })
      setFilterProduct(JSON.parse(JSON.stringify(fp)) as Array<Product>);
    }else {
      setFilterProduct(products);
    }
  }, [cat])

  useEffect(() => {
    if(color !== 'all'){
      const fp = products.filter(item => {
        if(item.color === color){
          return item;
        }
      })
      setFilterProduct(JSON.parse(JSON.stringify(fp)) as Array<Product>);
    }else {
      setFilterProduct(products);
    }
  }, [color])

  const filterShowHandler = () => {
    if (filterShow) {
      setFilterShow(false);
    } else {
      setFilterShow(true);
    }
    console.log({ filterShow });
  };

  const closeFilterHandler = () => {
    setOpenFilter(false);
  };

  const FilterHandler = () => {
    setOpenFilter(true);
  };
  


  const selectProduct = (product: Product) => {
    dispatch(setSelectProduct(product));
  }


  return (
    <Fragment>
      <section className="cmn-banner_shop about-banner" style={{backgroundSize: 'cover',
    backgroundPosition: 'center',}}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner-text text-center">
                <div className="title-page">
                  {/* <h2 className="">Products</h2> */}
                </div>
                {/* <div className="bread-crumb">
                  <a href="index.html">
                    Home<i className="fa fa-angle-right" aria-hidden="true"></i>
                  </a>
                  <strong>Products</strong>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="products-filter">
        <div className="container-fluid">
          <div className="row justify-content-between">
            <div className="col-lg-6">
              <div
                className="toggle-btn filter-btn"
                onClick={filterShowHandler}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="393pt"
                  viewBox="-4 0 393 393.99003"
                  width="393pt"
                >
                  <path d="m368.3125 0h-351.261719c-6.195312-.0117188-11.875 3.449219-14.707031 8.960938-2.871094 5.585937-2.3671875 12.3125 1.300781 17.414062l128.6875 181.28125c.042969.0625.089844.121094.132813.183594 4.675781 6.3125 7.203125 13.957031 7.21875 21.816406v147.796875c-.027344 4.378906 1.691406 8.582031 4.777344 11.6875 3.085937 3.105469 7.28125 4.847656 11.65625 4.847656 2.226562 0 4.425781-.445312 6.480468-1.296875l72.3125-27.574218c6.480469-1.976563 10.78125-8.089844 10.78125-15.453126v-120.007812c.011719-7.855469 2.542969-15.503906 7.214844-21.816406.042969-.0625.089844-.121094.132812-.183594l128.683594-181.289062c3.667969-5.097657 4.171875-11.820313 1.300782-17.40625-2.832032-5.511719-8.511719-8.9726568-14.710938-8.960938zm-131.53125 195.992188c-7.1875 9.753906-11.074219 21.546874-11.097656 33.664062v117.578125l-66 25.164063v-142.742188c-.023438-12.117188-3.910156-23.910156-11.101563-33.664062l-124.933593-175.992188h338.070312zm0 0"></path>
                </svg>
                <p>filter</p>
              </div>
            </div>
            <div className="col-lg-12">
              <h2 className="title-bestseller">BEST SELLERS</h2>
            </div>
            <div className="col-lg-12 position-relative filter-content-wrap">
              <div id="sidebar" className={filterShow ? "active" : ""} >
                <div className="list m-2 pt-5">
                  <div className="widget widget-category filter-category">
                    <div className="widget-title">
                      <h2>Categories</h2>
                      <button className="filter-close" onClick={filterShowHandler}><i className="fa-solid fa-circle-xmark"></i></button>
                    </div>
                    <div className="widget-content">
                      <ul className="categories-list">
                      <li onClick={() => setCat('all')}>
                              <a href="#">
                                <p>ALL</p>
                              </a>
                          </li>   
                        {
                          categories.map(item => 
                            <li onClick={() => setCat(item.Id)}>
                              <a href="#">
                                <p>{item.Name}</p>
                              </a>
                          </li>    
                          )
                        }
                         
                      </ul>
                    </div>
                  </div>
                  <div className="widget widget-category filter-category">
                    <div className="widget-title">
                      <h2>Price</h2>
                    </div>
                    <div className="widget-content">
                      <ul className="list-none ">
                      <li className="cat-item">
                          <input type="checkbox" value="0-10-00" />
                          <a
                            title="0 - $10.00"
                            className=" "
                            onClick={() => setPrice('0')}
                          >
                            ALL
                          </a>
                        </li>
                        <li className="cat-item">
                          <input type="checkbox" value="0-10-00" />
                          <a
                            title="0 - $10.00"
                            className=" "
                            onClick={() => setPrice('10-100')}
                          >
                            $10.00 - $100.00
                          </a>
                        </li>
                        <li className="cat-item">
                          <input type="checkbox" value="0-10-00" />
                          <a
                            title="0 - $10.00"
                            className=" "
                            onClick={() => setPrice('100-500')}
                          >
                            $100.00 - $500.00
                          </a>
                        </li>
                        <li className="cat-item">
                          <input type="checkbox" value="0-10-00" />
                          <a
                            title="0 - $10.00"
                            className=" "
                            onClick={() => setPrice('500-1000')}
                          >
                            $500.00 - $1000.00
                          </a>
                        </li>
                        <li className="cat-item">
                          <input type="checkbox" value="0-10-00" />
                          <a
                            title="0 - $10.00"
                            className=" "
                            onClick={() => setPrice('1000-5000')}
                          >
                            $1000.00 - $5000.00
                          </a>
                        </li>
                        
                      </ul>
                    </div>
                  </div>
                  {/* <div className="widget widget-category filter-category">
                    <div className="widget-title">
                      <h2>size</h2>
                    </div>
                    <div className="widget-content">
                      <ul className="list-none list-size">
                        <li>
                          <a href="" title="S" className=" ">
                            S
                          </a>
                        </li>
                        <li>
                          <a href="" title="M" className=" ">
                            M
                          </a>
                        </li>
                        <li>
                          <a href="" title="L" className=" ">
                            L
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  <div className="widget widget-category filter-category">
                    <div className="widget-title">
                      <h2>color</h2>
                    </div>
                    <div className="widget-content">
                      <ul className="list-none list_color">
                        <li onClick={() => setColor('red')}>
                          <input type="checkbox" value="beige" />
                          <a
                            href="#"
                            title="Beige"
                            className=""
                            style={{background:'red'}}
                          ></a>
                        </li>
                        <li onClick={() => setColor('black')}>
                          <input type="checkbox" value="beige" />
                          <a
                            href="#"
                            title="Beige"
                            className=""
                            style={{background:'black'}}
                          ></a>
                        </li>
                        <li onClick={() => setColor('green')}>
                          <input type="checkbox" value="beige" />
                          <a
                            href="#"
                            title="Beige"
                            className=""
                            style={{background:'green'}}
                          ></a>
                        </li>
                        <li onClick={() => setColor('orange')}>
                          <input type="checkbox" value="beige" />
                          <a
                            href="#"
                            title="Beige"
                            className=""
                            style={{background:'orange'}}
                          ></a>
                        </li>
                        <li onClick={() => setColor('all')}>
                          <input type="checkbox" value="beige" />
                          <a
                            href="#"
                            title="Beige"
                            className=""
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  
                  {/* <div className="widget widget-category filter-category">
                    <div className="widget-title">
                      <h2>BRAND</h2>
                    </div>
                    <div className="widget-content">
                      <ul className="categories-list">
                        <li>
                          <a href="#">
                            <p>Wineryn</p>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
              <div
                className={
                  filterShow ? "filter-content half-width" : "filter-content"
                }
              >
                <div className="row">
                  {/* <div className="best-seller-slider">
                    <Slider {...bestSeller}>
                     
                {
                  products.map((product, index) => 
                    <div key={index}>
                    <div className="best-seller-wrap text-center">
                      <div className="seller-img-product position-relative ">
                        <a href="./products" >
                          <img
                            src={product.Images[0]}
                            alt="product1"
                            style={{height: 560, width: 450}}
                          />
                        </a>
                        <ul className="best-seller-products p-0 m-0"></ul>
                      </div>
                      <div>
                        <h4>
                          <a href="./products">{product.Name}</a>{" "}
                        </h4>
                        <p>
                          <s className="price-old">${product.Current_price}</s>
                          <span className="price">${product.Original_price}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  )
                }
                    </Slider>
                  </div> */}

                  
                </div>
                {/* <div className="container-fluid"> */}
                <div className="row">
                    {
                      filterProduct.map((product, index) => 
                        <div key={index} className="col-lg-3 col-md-4 col-sm-6" onClick={() => selectProduct(product)}>
                        <ProductItem productInfo={product} />
                      </div>  
                      )
                    }
                    
                  </div>
                {/* </div> */}
                
              </div>
            </div>
          </div>
        </div>
      </section>

      <Filter
        open={openFilter}
        close={() => closeFilterHandler()}
      />
    </Fragment>
  );
};

export default ProductsList;
