import 'react-html5video/dist/styles.css';
import { DefaultPlayer as Video } from 'react-html5video';
import videolink from '../../assets/wineVideo.mp4'

const VideoS = () => {
    return (
        <Video loop muted controls={[]} style={{width:'60%', justifyContent:'center', alignSelf:'center', left: '20%', marginBottom: '60px'}}>
            <source src={videolink} type='video/webm'/>
        </Video>
    )
}

export default  VideoS;