import React, { Fragment } from "react";
import { addSubscribeUser } from "../../Firebase";

const Footer = () => {
  const [subscribeEmail, setsubscribeEmail] = React.useState('');
  const [Done, setDone]= React.useState(false);
  return (
    <Fragment>
    <section className="section-newsletter2">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 newsletter_left">
          <div className="newsletter_content">
            <div className="title_newletter">
              <h3 className="title_heading mb-0">
                Our <strong>NEWSLETTER!</strong>
              </h3>
            </div>
            <p className="mb-0 content">
              It only takes a second to be the first to find out about our
              latest news
            </p>
          </div>
        </div>
        <div className="col-lg-6 newletter_email">
          <form action="destroy" className="needs-validation">
            <div className="form-group m-0">
              <input
                type="email"
                name="EMAIL"
                value={subscribeEmail}
                onChange={(e) => setsubscribeEmail(e.target.value)}
                className="form-control"
                placeholder="Your email address..."
                required
              />
            </div>
            <button className="btn" type="submit" onClick={() => {
              if(subscribeEmail !== ''){
                addSubscribeUser(subscribeEmail, () => {
                  setDone(true);
                })
              }
            }}>
              Submit
            </button>
          </form>
          {
            Done ?? (<p style={{color:'green'}}>Susccessfully Added</p>) 
          }
          
        </div>
      </div>
    </div>
    </section>
    <footer>
      <div className="container-fluid">
        <div className="row footer-top">
          <div className="col-lg-5 col-md-6">
            <div className="info-footer">
              <div className="footer-logo">
                <a href="#">
                  <img src="./assets/images/Ezcape_Logo.png" alt="logo" />
                </a>
              </div>
              <div className="content-footer">
                <p className="mb-0 content_footer">
                  Sophisticated simplicity for the independent mind.
                </p>
              </div>

              <div className="list-icon">
                <ul className="list-inline list-unstyled mb-0">
                  <li className="list-inline-item">
                    <a href="" title="" target="_blank" className="social-item">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.facebook.com/ezcapewine" title="" target="_blank" className="social-item">
                    <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="" title="" target="_blank" className="social-item">
                    <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.instagram.com/ezcape_wine/" title="" target="_blank" className="social-item">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="info-footer">
              <div className="info_footer">
                <div className="title_footer">
                  <h4 className="mb-0 title_border"> Quick Links</h4>
                </div>
                <ul className="list-unstyled mb-0 p-0">
                <li>
                    <a href="/Home" title="About Us">
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="/products" title="About Us">
                      Shop ALL
                    </a>
                  </li>
                  <li>
                    <a href="/about" title="About Us">
                      About Us
                    </a>
                  </li>
                  <li>
                    <a href="/contact" title="About Us">
                     Contact Us
                    </a>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="info_footer">
              <div className="title_footer">
                <h4 className="mb-0 title_border">Help &amp; Information</h4>
              </div>
              <ul className="list-unstyled mb-0">
              <li>
                    <a href="/Privacypolicy" title="Privacy Policy">
                    PRIVACY POLICY
                    </a>
                  </li>
                  <li>
                    <a href="/Refundpolicy" title="Terms &amp; Conditions">
                    REFUND POLICY
                    </a>
                  </li>
                  
                <li>
                  <a href="/Shippingpolicy" title="Terms &amp; Conditions">
                  SHIPPING POLICY
                  </a>
                </li>
                <li>
                  <a href="/TermsOfServicepolicy" title="Contact">
                  TERMS OF SERVICE
                  </a>
                </li>

              </ul>
            </div>
          </div>
          {/* <div className="col-lg-3 col-md-4">
           
          </div> */}
        </div>
        <div className="copyright">
          <div className="container-fluid container-v1">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 text-lg-start text-md-start">
                <p className="text-copyright mb-0"></p>
                <div className="text-copyright mb-0">
                  {" "}
                  © Copyright 2022 |{" "}
                  <a
                    href="#"
                    rel=""
                    target="_blank"
                    className="underline_hover bold"
                  >
                    {" "}
                    WinerynStore{" "}
                  </a>{" "}
                  By{" "}
                  <a
                    href="#"
                    rel=""
                    target="_blank"
                    className="underline_hover link"
                  >
                    {" "}
                    ShopiLaunch.
                  </a>
                  <a href="#" rel="" target="_blank" className="">
                    {" "}
                    Powered by Shopify.
                  </a>
                </div>
                <p></p>
              </div>
              <div className="col-lg-6 col-md-6 text-lg-end text-md-end">
                <img
                  className="payment"
                  src="//cdn.shopify.com/s/files/1/0606/0805/3436/files/pay_copyright.png?v=1643165003"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    </Fragment>
  );
};

export default Footer;
