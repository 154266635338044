import { useMemo, useState } from 'react'
import Select from 'react-select'

const countryCodes = require('country-codes-list')
function CountryListt({select}) {
    const [value, setValue] = useState('')
    const myCountryCodesObject = countryCodes.customList('countryCode', '{countryNameEn}: +{countryCallingCode}')
    const newcou = Object.keys(myCountryCodesObject).map(value => {
        return {label: myCountryCodesObject[value], value: myCountryCodesObject[value]}
    })
    
    const changeHandler = (value) => {
      setValue(value)
      select(`+${value.value.split('+')[1]}`)
    }

    return <div><Select options={newcou} value={value} onChange={changeHandler}/></div>
}

export default CountryListt;