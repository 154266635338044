import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectProduct } from "../../Redux/Products/ProductManage";
import { RootState } from "../../Redux/Store";
import { Product } from "../../types";
import { Link, useNavigate } from "react-router-dom";

const SearchBar = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const products: Array<Product> = useSelector((state: RootState) => state.product.products);
  const [searchProduct, SetSearchProduct] = useState<Array<Product>>(products);
  const [searchName, setSearchName] = useState('');
  const { open, close } = props;
  const closeModal = () => {
    close();
  }

  useEffect(() => {
    SetSearchProduct(products.filter(item => {
      let LimitProperty = `${item.Festival}-${item.Name}-${item.Original_price}`
      return LimitProperty.toLocaleLowerCase().includes(searchName)
    }))
  }, [searchName]);

  const selectProduct = async(product: Product) => {
    await dispatch(setSelectProduct(product));
    closeModal();
    navigate('/product-detail')
  }


  return (
    <Fragment>
      <div className={open ? "search-top active" : "search-top"}>
        <span className="close-btn m-5 zindex-1" onClick={closeModal}>
          <i className="fa-regular fa-circle-xmark" style={{color: '#000'}} ></i>
        </span>
        <div className="drawer-search-top">
          <h3 className="drawer-search-title">Start typing and hit Enter</h3>
        </div>
        <form className="wg-search-form" action="/search">
          <input type="hidden" name="type" value="product" />
          <input
            type="text"
            name="q"
            value={searchName}
            onChange={e => setSearchName(e.target.value)}
            placeholder="Search anything"
            className="search-input js_engo_autocomplate"
          />
          <button type="submit" className="set-20-svg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 400 400"
              height="400"
              width="400"
              id="svg2"
              version="1.1"
            >
              <defs id="defs6"></defs>
              <g transform="matrix(1.3333333,0,0,-1.3333333,0,400)" id="g10">
                <g transform="scale(0.1)" id="g12">
                  <path
                    id="path14"
                    d="m 1312.7,795.5 c -472.7,0 -857.204,384.3 -857.204,856.7 0,472.7 384.504,857.2 857.204,857.2 472.7,0 857.3,-384.5 857.3,-857.2 0,-472.4 -384.6,-856.7 -857.3,-856.7 z M 2783.9,352.699 2172.7,963.898 c 155.8,194.702 241.5,438.602 241.5,688.302 0,607.3 -494.1,1101.4 -1101.5,1101.4 -607.302,0 -1101.399,-494.1 -1101.399,-1101.4 0,-607.4 494.097,-1101.501 1101.399,-1101.501 249.8,0 493.5,85.5 687.7,241 L 2611.7,181 c 23,-23 53.6,-35.699 86.1,-35.699 32.4,0 63,12.699 86,35.699 23.1,22.801 35.8,53.301 35.8,85.898 0,32.602 -12.7,63 -35.7,85.801"
                  ></path>
                </g>
              </g>
            </svg>
          </button>
        </form>


        <div className="product-search-lists">
          <div className="row">
            {
              searchProduct.map((item, index) => 
                <div key={index} className="col-lg-4 col-md-6 col-sm-6" onClick={() => selectProduct(item)}>
                <div className="product-search-lists-wrapper">
                  <ul className="prod_sidebar">
                    <li className="product_info">
                      <img src={item.Images[0]} alt="" />
                      <div className="product-search-inner-text">
                        <h4 className="title-product">
                          {/* <p style={{fontSize: 12}}>{item.Festival} </p> */}
                          <span className="hightlight" style={{fontSize: 12, lineHeight: 0}}> {item.Name}</span>
                        </h4>
                        <span>
                          <p className="price-product mb-0">
                            <span className="price">${item.Original_price} USD</span>
                          </p>
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
                </div>    
              )
            }
          </div>
        </div>
      </div>
      <div
        className={open ? "bg_search_box active" : "bg_search_box "}
        onClick={closeModal}
      ></div>
    </Fragment>
  );
};

export default SearchBar;
