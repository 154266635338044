import { log } from "console";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { addDefaultAddress, AddToCart, clearCart, getAllAddress, getDefaultAddress, getPromocode, setOrder, updateDefaultAddress, updateProductStock } from "../../Firebase";
import { DefaultAddress, setopenLoginModule } from "../../Redux/Authentication/Login";
import { addtoCart, setcomeAfterPayment, setPaymentResponse } from "../../Redux/Products/ProductManage";
import { RootState } from "../../Redux/Store";
import { Address, CartModel, PaymentStatusDetail, PaymentUrlResponse, PromoCode, User } from "../../types";
import Modal from "react-responsive-modal";

const Checkout = () => {
  const dispatch = useDispatch();
  const search = useLocation().search;
  const userinfo: User = useSelector((state: RootState) => state.login.user);
  const MyCartDetail: Array<CartModel> = useSelector((state: RootState) => state.product.cart);
  const paymentResponse: PaymentUrlResponse = useSelector((state: RootState) => state.product.paymentResponse);
  const isComeAgain: boolean = useSelector((state: RootState) => state.product.comeAfterPayment);
  const dAddress: Address = useSelector((state: RootState) => state.login.address);
  const [total, setTotal] = useState(0);
  const [shippingCharge, setshippingCharge] = useState(10);
  const [addressList, setaddtessList] = useState([] as Array<Address>);
  const [promocode, setPromocode] = useState('');
  const [error, setError] = useState(false);
  const [modelAddress, setmodelAddress] = useState(false);
  const [tax, setTax] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [progress, setProgress] = useState('information');
  const [address, setAddress] = useState({
    Active: true,
    Country: "",
    FirstName: "",
    LastName: "",
    PinCode: "",
    Area: "",
    Address: '',
    City: "",
    State: "",
    Email: ""
  })
  let subTotal = 0;
  let taxx = 0;
  

  const handleChange = (e: any) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value
    })
  }


  const PaymentProcesser = async () => {
    if(JSON.stringify(userinfo) === "{}"){
      dispatch(setopenLoginModule(true));
      return;
    }
    let paymentProduct: any = [];
    MyCartDetail.map(item => {
      let taxx = tax / MyCartDetail.length;
      let finalPrice = item.Price+taxx - (((item.Price+taxx) * discount) / 100) + shippingCharge
      let pricedata = {price_data: {currency: 'USD',product_data: {name: item.Product?.Name},unit_amount: Math.round(finalPrice)*100},quantity:item.Quantity}
      // let pricedata = {price_data: {currency: 'USD',product_data: {name: item.Product?.Name},unit_amount: 100},quantity:item.Quantity}
      paymentProduct.push({...pricedata});
    });
    let data = {customer_id: userinfo.uid,product_id: "123456",product_data: paymentProduct, quantity: MyCartDetail.length,currency: "USD"}
    console.log(JSON.stringify(data))
    let response = await fetch(`https://ezcapewine.pythonanywhere.com/Payment_method`, {
        method: 'POST',
        mode: 'cors',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
      })
    let res = (await response.json()) as PaymentUrlResponse;
    console.log('res', res);
    
    dispatch(setPaymentResponse(res))
    dispatch(setcomeAfterPayment(true));
    setTimeout(() => {
      window.location.href =res.data.paymentUrl;
    }, 2000);
  }

  const countTotalPrice = () => {
    subTotal = 0;
    taxx = 0;
    MyCartDetail.map(item => {
      subTotal+= Number(item.Product?.Current_price) * item.Quantity
    })
    taxx = (subTotal * 8) / 100
    setTax(taxx);
    subTotal += (subTotal * 8) / 100
    
    setTotal(totall => totall = subTotal)

    if(dAddress) {setAddress(dAddress)}
  }

  useEffect(() => {
    console.log('mycartDetail', MyCartDetail.length);
    
    if(MyCartDetail.length === 0) {
      window.location.href ='./'; 
    }
    countTotalPrice();
    const name = new URLSearchParams(search).get('isPaid');
    if(isComeAgain){
      if(name === 'success'){
        console.log('success', 'succes');
        
        countTotalPrice()
        confirmPaymentStatus()
      }else {
        dispatch(setcomeAfterPayment(false));
      }
      
    }

    
  }, [MyCartDetail, dAddress])


  const doShopping = () => {
    if(JSON.stringify(userinfo) === "{}"){
      dispatch(setopenLoginModule(true));
      return;
    }
    addDefaultAddress(userinfo.uid , address as Address, () => {
      console.log('address',address);
      dispatch(DefaultAddress(address as Address));
      setProgress('Shipping')
    })
  }

  const onAddressChanged = (e: any) => {
    console.log('sdfsdfs', 'kbhjv');
    console.log('sdfsdfs', e.currentTarget.checked);
    
  };

  const confirmPaymentStatus = async () => {
    
    if(JSON.stringify(userinfo) === "{}"){
      dispatch(setopenLoginModule(true));
      return;
    }
    const data = new FormData();
    data.append("p_id", paymentResponse.data.payment_detail.p_stripe_id);
    data.append("id", paymentResponse.data.payment_detail.p_id.toString());
    data.append("f_key", paymentResponse.data.firebase_Key);
    console.log(Object.fromEntries(data))
    
    let response = await fetch(`https://ezcapewine.pythonanywhere.com/payment_status`, {
      method: 'POST',
      mode: 'cors',
      body: data
    })
    let res = (await response.json()) as PaymentStatusDetail;
    
    if(res.data.PaymentDetail.payment_status === 'paid'){
      getDefaultAddress(userinfo.uid, data => {
        const order = {
          TotalPrice: subTotal+taxx,
          PhoneNumber: userinfo.phoneNumber,
          Address: JSON.stringify(data),
          Timestamp: Date(),
          Payment_Status: 'Sccuses',
          Product: JSON.stringify(MyCartDetail),
          BillingId: paymentResponse.data.firebase_Key
  
        }
        setOrder(userinfo.uid, order, () => {
          updateProductStock(MyCartDetail, () => {
            clearCart(userinfo.uid, async () => {
              await dispatch(addtoCart(Array<CartModel>()));
              dispatch(setcomeAfterPayment(false));
              window.location.href ='./PurchaseSuccess';
            })
          });
          
        })  
      })
      
      
    }else{
     
    }
    
  }

  useEffect(() => {
    
    updateaddressList();
  }, []);

  const updateaddressList = () => {
    setaddtessList([] as Array<Address>);
    getAllAddress(userinfo.uid, data => {
      setaddtessList(addd => addd = data);
      
    })
  }

  const appltPromocode = () => {
    
    
    getPromocode(promocode, code => {
      if(code.Code === 'FREESHIP'){
        setshippingCharge(0)
      } else {
        setDiscount(Number(code.Percentage));
        setshippingCharge(10)
      }
      
      setError(false);
      countTotalPrice();
    }, () => {
      setshippingCharge(10)
      setError(true);
      countTotalPrice();
    })
  }
  return (
    <Fragment>
      <section className="checkout-main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 checkout-form">
                <span className="_2EHK3">
                  Wineryn - Wine
                </span>
                <ul className="payment-step" >
                    <li className="payment-steps">
                      <span className="step-font">
                        <a className="text-link">Cart</a>
                      </span>
                      <span className="left-icon">
                        <svg viewBox="0 0 10 10" className="" focusable="false" aria-hidden="true" height={10}>
                          <path d="M2 1l1-1 4 4 1 1-1 1-4 4-1-1 4-4"></path>
                        </svg>
                      </span>
                    </li>
                  
                    <li className="payment-steps" aria-current="step">
                      <span className="step-font">
                        {
                          progress === 'information' ? <a className="text-link">Information</a> : <span>Information</span>
                        }
                        
                        </span>
                      <span className="left-icon">
                        <svg viewBox="0 0 10 10" className="" focusable="false" aria-hidden="true" height={10}>
                          <path d="M2 1l1-1 4 4 1 1-1 1-4 4-1-1 4-4"></path>
                        </svg>
                      </span>
                    </li>
                  
                  
                    <li className="payment-steps">
                      <span className="step-font">
                      {
                          progress === 'Shipping' ? <a className="text-link step-font">Shipping</a> : <span>Shipping</span>
                        }
                      </span>
                      
                      <span className="left-icon">
                        <svg viewBox="0 0 10 10" className="" focusable="true" aria-hidden="true" height={10}>
                          <path d="M2 1l1-1 4 4 1 1-1 1-4 4-1-1 4-4"></path>
                        </svg>
                      </span>
                    </li>
                  
                  
                    <li className="step-font payment-steps">
                      <span className="">Payment</span>
                    </li>

                </ul>
                
                {
                progress === 'information' ? 
               ( <div>
                <div className="mb-3">
                  <h2>Contact information</h2>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    onChange={handleChange}
                    value={address.Email}
                    name="Email"
                  />
                </div>
                <div className="mb-5 mt-3 col-lg-11">
                  <span style={{fontFamily: 'Helvetica Neue', fontSize: 13}}>You may receive text messages related to order confirmation and shipping updates. Reply STOP to unsubscribe. Reply HELP for help. Message frequency varies. Msg & data rates may apply. View our Privacy policy and Terms of service.</span>
                </div>
                
                <div style={{color:'blue', fontSize: 20, marginBottom: '10px'}} onClick={() => setmodelAddress(true)}>Select Default Address</div>
                <div className="mb-3">
                  <h2>Shipping address</h2>
                  <select 
                    name="Country" 
                    id="" 
                    className="form-control" 
                    onChange={handleChange}
                    value={address.Country} >
                    <option value="IN">India</option>
                    <option value="AF">Afghanistan</option>
                  </select>
                </div>
                <div className="mb-3">
                  <div className="row">
                    <div className="col-lg-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First name (optional)"
                        onChange={handleChange}
                        value={address.FirstName} 
                        name="FirstName"
                      />
                    </div>
                    <div className="col-lg-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last name"
                        onChange={handleChange}
                        value={address.LastName} 
                        name="LastName"
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Address"
                    onChange={handleChange}
                    value={address.Address} 
                    name="Address"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Apartment, suite, etc. (optional)"
                    onChange={handleChange}
                    value={address.Area} 
                    name="Area"
                  />
                </div>
                <div className="mb-3">
                  <div className="row">
                    <div className="col-lg-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="city"
                        onChange={handleChange}
                        value={address.City}
                        name="City" 
                      />
                    </div>
                    <div className="col-lg-4">
                      <select name="State" id="" className="form-control" onChange={handleChange}
                    value={address.State} >
                        <option value="IN">India</option>
                        <option value="AF">Afghanistan</option>
                      </select>
                    </div>
                    <div className="col-lg-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="PIN code"
                        onChange={handleChange}
                        value={address.PinCode} 
                        name="PinCode"
                      />
                    </div>
                  </div>
                  <div className="shopping-wrap d-flex align-items-center justify-content-between">
                    <a href="" className="return-cart-btn">
                      <svg
                        viewBox="0 0 10 10"
                        className="_1fragemi _1fragem1h _1fragem4q _1fragem4p"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="M8 1L7 0 3 4 2 5l1 1 4 4 1-1-4-4"></path>
                      </svg>
                      Return to cart
                    </a>
                    
                    <button className="continue-shopping" onClick={doShopping}>
                      Continue to shipping
                    </button>
                  </div>
                </div>
              </div>)
              :
              (<div>
                
                <div className="total-lists mt-3" style={{alignContent:'center'}}>
                <ul className="p-2 m-0 checkout_information">
                  <li className="p-2">
                    <div className="total-lists">
                      <ul>
                        <li>
                          <h5 className="m-2">Contact</h5>
                          <h5 className="m-2">+91 7405880343</h5>
                        </li>
                      </ul>
                    </div>
                    <h5>Change</h5>
                  </li>
                  <li className="checkout_devide"></li>
                  <li className="p-2">
                    <div className="total-lists">
                      <ul>
                        <li>
                          <h5 className="m-2">Ship To</h5>
                          <h5 className="m-2">D-202 Shiv palace</h5>
                        </li>
                      </ul>
                    </div>
                    <h5>Change</h5>
                  </li>
                </ul>

                <div className="col-lg-12 m-5">
                  <div className="m-5">
                  <div className="needs-validation">
                    <div className="form-group m-0">
                      <input
                        className="form-control"
                        placeholder="PromoCode"
                        value={promocode}
                        onChange={e => setPromocode(e.target.value)}
                        required
                      />
                    </div>
                    <button className="btn" onClick={appltPromocode}>
                      Apply
                    </button>
                    
                  </div>
                  {error && 
                    <span>Prmocode is not match</span>
                  }
                  </div>
            </div>
                <div className="mt-5">
                  <h2 className="color-black">Shipping Method</h2>
                </div>
                <ul className="p-2 mt-3 checkout_information">
                  <li className="p-2">
                    <div className="total-lists">
                      <ul>
                        <li>
                          <h5 className="m-2">Standard</h5>
                        </li>
                      </ul>
                    </div>
                    <h5 className="checkoutMethod_font">${shippingCharge}.00</h5>
                  </li>
                </ul>

              </div>
              <div className="shopping-wrap d-flex align-items-center justify-content-between">
                    <a href="" className="return-cart-btn">
                      <svg
                        viewBox="0 0 10 10"
                        className="_1fragemi _1fragem1h _1fragem4q _1fragem4p"
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="M8 1L7 0 3 4 2 5l1 1 4 4 1-1-4-4"></path>
                      </svg>
                      Return to Information
                    </a>
                    <button className="continue-shopping" onClick={()=> PaymentProcesser()}>
                      Continue to Payment
                    </button>
                  </div>
                
              </div>)
              
              }
              

              


            </div>
            <div className="col-lg-6 checkout-main-wrap">
              <div className="checkout-inner">
               {
                MyCartDetail.map(item => 
                  <div className="checkout-details-list">
                  <div className="checkout-details-list-left">
                    <div className="checkout-details-list-img">
                      <img src={item.Product?.Images[0]} alt="" />
                      <span className="budge-cart">{item.Quantity}</span>
                    </div>
                    <div>
                      <p>{item.Product?.Name}</p>
                    </div>
                  </div>
                  <div>
                    <p>${item.Product?.Current_price}</p>
                  </div>
                </div>)
               }
              </div>
              <div className="checkout_devide mt-4 mb-2"></div>
              <div className="total-lists mt-3">
                <ul className="p-0 m-0">
                  <li className="mt-3">
                    <h6>Subtotal</h6>
                    <h6>${total}.00</h6>
                  </li>
                  <li className="mt-3">
                    <h6>Shipping Charge</h6>
                    <h6>${shippingCharge}.00</h6>
                  </li>
                  <li className="mt-3">
                    <h6>Estimated Taxes</h6>
                    <h6>${tax}.00</h6>
                  </li>
                </ul>
              </div>
              <div className="checkout_devide mt-4 mb-2"></div>
              <div className="total-lists">
                <ul className="p-0 m-0 mt-4">
                  <li>
                    <h2>Total</h2>
                    <h2>${total + shippingCharge}.00</h2>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal open={modelAddress}
      onClose={() => setmodelAddress(!modelAddress)}
      center
      styles={{modal: {width: '40%'}}}>
        
      <div className="mb-5">
        <h3>Selection Address</h3>
                {
                  addressList.map(add=> {
                    return(
                      <div style={{flexDirection:'row'}} className="row">
                        <div className="col-lg-1 p-2">
                         <input type="checkbox" name="address" checked={add.Active} onChange={(e) => {

                              updateDefaultAddress(userinfo.uid, add.id, e.target.checked, () => {
                                      
                                addressList.map(aadd => {
                                  if(aadd.id !== add.id){
                                    updateDefaultAddress(userinfo.uid, aadd.id, false, () => {
                                    })      
                                  }
                                })
                                      
                              })
                         }} />
                            </div>
                            <div className="col-lg-11 p-2">
                            <b>{`${add.FirstName} ${add.LastName}`}</b>
                            <p>{`${add.Email}`}</p>
                              <p>{`${add.Address} ${add.Area} ${add.City} ${add.State} ${add.Country} ${add.PinCode}`}</p>
                              

                        </div>
                      </div>
                    );
                  })
                }
                </div>
      </Modal>
    </Fragment>
  );
};

export default Checkout;
