import React, { Fragment } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import VideoS from "./Video";


var behindBars = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrow: false,
  navigtion: false,
};

var AboutInstagram = {
  infinite: true,
  speed: 300,
  loop: true,
  margin: 10,
  slidesToShow: 5,
  arrows: true,
  slidesToScroll: 1,
  autoplay: true,
  dots: false,
  autoplaySpeed: 3000,
};

const About = () => {
  return (
    <Fragment>
      <section className="cmn-banner about-banner" style={{backgroundSize: 'cover',
    backgroundPosition: 'center'}}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner-text text-center">
                <div className="title-page">
                  {/* <h2 className="">About Us</h2> */}
                </div>
                {/* <div className="bread-crumb">
                  <a href="index.html">
                    Home<i className="fa fa-angle-right" aria-hidden="true"></i>
                  </a>
                  <strong>About Us</strong>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="aboutus-pages p-70">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 aboutus-content">
              <div className="info-intro ">
                <h3 className="title_intro">Our Story</h3>
                <p className="des_intro">THE HIGH STRESS FAVOUTIRE</p>
                <div className="content_intro">
                  <p style={{color:'black', fontSize: '20px'}}>
                  At EZCAPE WINE, we believe each moment is a chance to share your light with the world. That is why our motto is based on being uniquely YOU. Because, you are your greatest superpower and we are here to help you celebrate just that! Our Bubbly flavored wines are carefully crafted and perfectly blended to give you a unique Zen experience. Tastes that are Radiant, Balanced, Embodied, and Bold - just like you. 
                  </p>
                  <p style={{color:'black', fontSize: '20px'}}>
                  We have been operating for over 1 year and have managed to build up a reputable establishment online. We have a great team and aim to grow our business more and more by offering our customers the best Wine and Customer Service available. If you have any questions about our products, or if would like to check the availability of an item, please use the “Contact Us” page to get in touch.
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-6 about_image">
              <img src="./assets/images/about1.png" alt="" />
            </div> */}
          </div>
          {/* <div className="row align-items-center">
            <div className="col-lg-6 aboutus-content">
              <div className="info-intro ">
                <h3 className="title_intro">Our Story</h3>
                <p className="des_intro">THE HIGH STRESS FAVOUTIRE</p>
                <div className="content_intro">
                  <p>
                    Praesent metus tellus, elementum eu, semper a, adipiscing
                    nec, purus. Vestibulum volutpat pretium libero. In ut quam
                    vitae odio lacinia tincidunt. Etiam ut purus mattis mauris
                    sodales aliquam. Aenean massa.
                  </p>
                  <p>
                    In dui magna, posuere eget, vestibulum et, tempor auctor,
                    justo. Vivamus consectetuer hendrerit lacus. In hac
                    habitasse platea dictumst. Ut tincidunt tincidunt erat.
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 about_image">
              <img src="./assets/images/about1.png" alt="" />
            </div>
          </div> */}
        </div>
      </section>
      <section>
        <VideoS/>
      </section>

      <section className="about-servicebox">
        <div className="container">
          <div className="row">
          
            <div className="col-lg-4 box-service">
              <div className="icon-box-icon">
                <span className="shpi-icon"></span>
              </div>
              <span className="title">Design</span>
              <p className="content">
                Praesent metus tellus, elementum eu, semper Vestibulum volutpat
                pretium libero
              </p>
            </div>
            <div className="col-lg-4 box-service">
              <div className="icon-box-icon">
                <span className="shpi-icon"></span>
              </div>
              <span className="title">Inovation</span>
              <p className="content">
                Praesent metus tellus, elementum eu, semper Vestibulum volutpat
                pretium libero
              </p>
            </div>
            <div className="col-lg-4 box-service">
              <div className="icon-box-icon">
                <span className="shpi-icon"></span>
              </div>
              <span className="title">Inovation</span>
              <p className="content">
                Praesent metus tellus, elementum eu, semper Vestibulum volutpat
                pretium libero
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="behind-brands p-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center title_general">
                <h3>Behind The Brands</h3>
                <p>
                  We are a female-founded, 100% woman-led team of collaborative
                  dreamers who value innovation, curiosity and free-thinking
                  fearlessness in everything that we do. We take immeasurable
                  pride in our work, intentionally stitching love into the very
                  fiber and fabric of our designs. We are small, but we are a
                  mighty group of talented individuals dedicated to bringing you
                  otherworldly designs with imagery to match.
                </p>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="behind-brands-slider">
                <Slider {...behindBars}>
                  <div>
                    <div className="behind-brands-wrap text-center img_teams">
                      <a href="">
                        <img src="./assets/images/ourteam.png" alt="" />
                      </a>
                      <ul className="social-link">
                        <li>
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-pinterest"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="info_teams text-center">
                      <h4>
                        <a href="#">Ferguson</a>
                      </h4>
                      <p className="mb-0">Designer</p>
                    </div>
                  </div>
                  <div>
                    <div className="behind-brands-wrap text-center img_teams">
                      <a href="">
                        <img src="./assets/images/ourteam-1-4.png" alt="" />
                      </a>
                      <ul className="social-link">
                        <li>
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-pinterest"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="info_teams text-center">
                      <h4>
                        <a href="#">Ferguson</a>
                      </h4>
                      <p className="mb-0">Designer</p>
                    </div>
                  </div>
                  <div>
                    <div className="behind-brands-wrap text-center img_teams">
                      <a href="">
                        <img src="./assets/images/ourteam-1-2.png" alt="" />
                      </a>
                      <ul className="social-link">
                        <li>
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-pinterest"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="info_teams text-center">
                      <h4>
                        <a href="#">Ferguson</a>
                      </h4>
                      <p className="mb-0">Designer</p>
                    </div>
                  </div>
                  <div>
                    <div className="behind-brands-wrap text-center img_teams">
                      <a href="">
                        <img src="./assets/images/ourteam.png" alt="" />
                      </a>
                      <ul className="social-link">
                        <li>
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-pinterest"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="info_teams text-center">
                      <h4>
                        <a href="#">Ferguson</a>
                      </h4>
                      <p className="mb-0">Designer</p>
                    </div>
                  </div>
                  <div>
                    <div className="behind-brands-wrap text-center img_teams">
                      <a href="">
                        <img src="./assets/images/ourteam.png" alt="" />
                      </a>
                      <ul className="social-link">
                        <li>
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-pinterest"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="info_teams text-center">
                      <h4>
                        <a href="#">Ferguson</a>
                      </h4>
                      <p className="mb-0">Designer</p>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section> */}


      <section className="about-instagram">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 p-0">
              <div className="about-instagram-slider">
                {/* <Slider {...AboutInstagram}>
                  <div>
                    <div className="as-seenyou-img position-relative">
                      <img src="./assets/images/instagram2.png" alt="" />
                      <div className="content-instagram">
                        <a href="#" className="">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="as-seenyou-img position-relative">
                      <img src="./assets/images/instagram2.png" alt="" />
                      <div className="content-instagram">
                        <a href="#" className="">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="as-seenyou-img position-relative">
                      <img src="./assets/images/instagram2.png" alt="" />
                      <div className="content-instagram">
                        <a href="#" className="">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="as-seenyou-img position-relative">
                      <img src="./assets/images/instagram2.png" alt="" />
                      <div className="content-instagram">
                        <a href="#" className="">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="as-seenyou-img position-relative">
                      <img src="./assets/images/instagram2.png" alt="" />
                      <div className="content-instagram">
                        <a href="#" className="">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="as-seenyou-img position-relative">
                      <img src="./assets/images/instagram2.png" alt="" />
                      <div className="content-instagram">
                        <a href="#" className="">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </Slider> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default About;
