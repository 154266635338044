import React from "react";
import { Fragment } from "react";


const BlogList = () => {
  return (
    <Fragment>
      <section className="cmn-banner_bloh contact-us-banner">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="banner-text text-center">
                    <div className="title-page">
                      <h2 className="">News</h2>
                    </div>
                    <div className="bread-crumb">
                      <a href="index.html">
                        Home<i className="fa fa-angle-right" aria-hidden="true"></i>
                      </a>
                      <strong>News</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </section>

      <section className="blog blog-list">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="blog-wrap blog-list-main-wrapper">
                <div className="blog-picture position-relative">
                  <div className="date">
                    <span className="day"> 24</span>
                    <span className="month">Jan</span>
                  </div>
                  <a href="#">
                    <img src="./assets/images/blog.png" alt="blog" />
                  </a>
                </div>
                <div className="info-blog text-start">
                  <a className="blog-cate" href="">
                    News
                  </a>
                  <h4 className="title-blog">
                    <a href="">A Lesson In Wines</a>
                  </h4>
                  <p className="content mb-0">
                    Where can I get some? Etiam risus diam, porttitor vitae
                    ultrices quis, dapibus id dolor.Morbi venenatis lacinia
                    rhoncus.Lorem ipsum dolor sit amet,...
                  </p>
                  <a href="#" className="read-more">read more</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-wrap blog-list-main-wrapper">
                <div className="blog-picture position-relative">
                  <div className="date">
                    <span className="day"> 24</span>
                    <span className="month">Jan</span>
                  </div>
                  <a href="#">
                    <img src="./assets/images/blog.png" alt="blog" />
                  </a>
                </div>
                <div className="info-blog text-start">
                  <a className="blog-cate" href="">
                    News
                  </a>
                  <h4 className="title-blog">
                    <a href="">A Lesson In Wines</a>
                  </h4>
                  <p className="content mb-0">
                    Where can I get some? Etiam risus diam, porttitor vitae
                    ultrices quis, dapibus id dolor.Morbi venenatis lacinia
                    rhoncus.Lorem ipsum dolor sit amet,...
                  </p>
                  <a href="#" className="read-more">read more</a>

                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-wrap blog-list-main-wrapper">
                <div className="blog-picture position-relative">
                  <div className="date">
                    <span className="day"> 24</span>
                    <span className="month">Jan</span>
                  </div>
                  <a href="#">
                    <img src="./assets/images/blog.png" alt="blog" />
                  </a>
                </div>
                <div className="info-blog text-start">
                  <a className="blog-cate" href="">
                    News
                  </a>
                  <h4 className="title-blog">
                    <a href="">A Lesson In Wines</a>
                  </h4>
                  <p className="content mb-0">
                    Where can I get some? Etiam risus diam, porttitor vitae
                    ultrices quis, dapibus id dolor.Morbi venenatis lacinia
                    rhoncus.Lorem ipsum dolor sit amet,...
                  </p>
                  <a href="#" className="read-more">read more</a>

                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-wrap blog-list-main-wrapper">
                <div className="blog-picture position-relative">
                  <div className="date">
                    <span className="day"> 24</span>
                    <span className="month">Jan</span>
                  </div>
                  <a href="#">
                    <img src="./assets/images/blog.png" alt="blog" />
                  </a>
                </div>
                <div className="info-blog text-start">
                  <a className="blog-cate" href="">
                    News
                  </a>
                  <h4 className="title-blog">
                    <a href="">A Lesson In Wines</a>
                  </h4>
                  <p className="content mb-0">
                    Where can I get some? Etiam risus diam, porttitor vitae
                    ultrices quis, dapibus id dolor.Morbi venenatis lacinia
                    rhoncus.Lorem ipsum dolor sit amet,...
                  </p>
                  <a href="#" className="read-more">read more</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-wrap blog-list-main-wrapper">
                <div className="blog-picture position-relative">
                  <div className="date">
                    <span className="day"> 24</span>
                    <span className="month">Jan</span>
                  </div>
                  <a href="#">
                    <img src="./assets/images/blog.png" alt="blog" />
                  </a>
                </div>
                <div className="info-blog text-start">
                  <a className="blog-cate" href="">
                    News
                  </a>
                  <h4 className="title-blog">
                    <a href="">A Lesson In Wines</a>
                  </h4>
                  <p className="content mb-0">
                    Where can I get some? Etiam risus diam, porttitor vitae
                    ultrices quis, dapibus id dolor.Morbi venenatis lacinia
                    rhoncus.Lorem ipsum dolor sit amet,...
                  </p>
                  <a href="#" className="read-more">read more</a>

                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-wrap blog-list-main-wrapper">
                <div className="blog-picture position-relative">
                  <div className="date">
                    <span className="day"> 24</span>
                    <span className="month">Jan</span>
                  </div>
                  <a href="#">
                    <img src="./assets/images/blog.png" alt="blog" />
                  </a>
                </div>
                <div className="info-blog text-start">
                  <a className="blog-cate" href="">
                    News
                  </a>
                  <h4 className="title-blog">
                    <a href="">A Lesson In Wines</a>
                  </h4>
                  <p className="content mb-0">
                    Where can I get some? Etiam risus diam, porttitor vitae
                    ultrices quis, dapibus id dolor.Morbi venenatis lacinia
                    rhoncus.Lorem ipsum dolor sit amet,...
                  </p>
                  <a href="#" className="read-more">read more</a>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </Fragment>
  );
};

export default BlogList;
