import React, { Fragment, useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/Store";
import { Banner, Category, Product } from "../../types";
import { useNavigate } from "react-router-dom";
import { ReadBanner } from "../../Firebase";

const Home = () => {
  const navigation = useNavigate();
  const userinfo = useSelector((state: RootState) => state.login.user);
  const products: Array<Product> = useSelector((state: RootState) => state.product.products); 

  const [banner, setBanner] = useState<Array<Banner>>();

  useEffect(() => {
    ReadBanner(data => {
      setBanner(data);
    })
  }, []);
  

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrow: true,
    navigtion: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  var reviews = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrow: true,
    navigtion: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  var BannerSlider = {
    
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: true,
    navigtion: false,
    
  };


  return (
    <Fragment>
      <section className="main-banner">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 p-0">
              <div className="fade">
                <Slider {...BannerSlider} >
                  {
                    banner?.map(item => 
                      <div>
                      <div className="banner-slider-main wow fadeInUp" 
                      >
                        <img
                            src={`${item.banner}`}
                          />
                        {/* <div className="box-content box-content-right">
                          <div className=" text-center box-info">
                            <div className="box-title1 animated">
                              <h3 className="title-small mb-10">DELIGHTFUL1</h3>
                            </div>
                            <div className="box-title animated">
                              <h3 className="titlebig mb-10">Vintage Wine1</h3>
                            </div>
                            <div className="box-title2 animated">
                              <h3 className="title-small mb-10">
                                Sale up to 20% Off1
                              </h3>
                            </div>
                            <div className="animated">
                              <a className="button-main1" href="" tabIndex={0}>
                                Shop now1
                              </a>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      </div>
                   
                        
                    )
                  }
               
                </Slider>
                
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="p-70 why-choose-us wow fadeInUp">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="title">
                <h3>Why Choose us?</h3>
                <p>
                At EZCAPE WINE, we're passionate about the journey that is life. Our Bubbly flavoured wines are the result of careful crafting and perfect blending, offering a truly distinctive and Zen-like experience. We invite you to join us on a journey of oneness, peace, and enlightenment, whether you're gathered around the dinner table or soaking up the sun on the beaches of the Bahamas. With Ezcape Wine, every moment is a reason to celebrate the NOW.  
                </p>
              </div>
              <div className="why-choose-wrap">
                <div className="why-choose-inner">
                  <div className="why-choose-img">
                    <img src="./assets/images/choose-icon1.png" alt="choose" />
                  </div>
                  <div className="why-choose-text">
                    <h3>BRUT</h3>
                    <p>A sparkling wine that offers a refreshing and lively profile with a dry texture, complemented by hints of tangy lemon, fragrant elderflower, and zesty bergamot. </p>
                  </div>
                </div>
                <div className="why-choose-inner">
                  <div className="why-choose-img">
                    <img src="./assets/images/choose-icon2.png" alt="choose" />
                  </div>
                  <div className="why-choose-text">
                    <h3>RED</h3>
                    <p>Indulge in a vibrant and fruity red blend with a medley of flavours, including juicy muddled raspberry, luscious black cherry, and aromatic fennel notes.</p>
                  </div>
                </div>
                <div className="why-choose-inner">
                  <div className="why-choose-img">
                    <img src="./assets/images/choose-icon3.png" alt="choose" />
                  </div>
                  <div className="why-choose-text">
                    <h3>ROSE</h3>
                    <p>An effortless Rosé, with hints of red peach, wisteria, and watermelon rind, perfect for a refreshing sip.</p>
                  </div>
                </div>
                {/* <div className="why-choose-inner">
                  <div className="why-choose-img">
                    <img src="./assets/images/choose-icon4.png" alt="choose" />
                  </div>
                  <div className="why-choose-text">
                    <h3>Catering Bowls</h3>
                    <p>Lorem ipsum dolor sit amet</p>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="image-main">
                <img src="./assets/images/choose-us.png" alt="contact" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-best-seller">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="title2 text-center">
                <h3>OUR BEST SELLER</h3>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="best-seller-slider">
                <Slider {...settings}>

                {
                  products.slice(0,5).map((product, index) => 
                    <div key={index}>
                    <div className="best-seller-wrap text-center">
                      <div className="seller-img-product position-relative" onClick={() => navigation('products')}>
                                                  <img
                            src={product.Images[0]}
                            alt="product1"
                        
                          />
                        
                        <ul className="best-seller-products p-0 m-0"></ul>
                      </div>
                      <div>
                        <h4>
                          <a href="./products">{product.Name}</a>{" "}
                        </h4>
                        <p>
                          <s className="price-old">${product.Original_price}</s>
                          <span className="price"> ${product.Current_price}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  )
                }


                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="p-70">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div className="banner-content title">
                <h3>EZCAPE ROSÉ</h3>
                <p>
                Indulge in the perfect Rosé ezcape with our expertly crafted blend of aromatic freshness and subtle, fruity notes. Our Rosé offers a crisp and suave finish, striking the ideal balance between flavours for an unforgettable experience.
                </p>
                <a href="./products" className="cmn-btn">
                  Shop now
                </a>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="image-main">
                <a href="#">
                  <img src="./assets/images/business.png" alt="business" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="p-70 free-wine">
        <div className="container-fluid">
          <div className="row align-items-center ">
            <div className="col-lg-8">
              <div className="image-main">
                <a href="#">
                  <img src="./assets/images/free-wine.png" alt="free-wine" />
                </a>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="banner-content title">
                <h3>Ezcape Sparkling Moscato</h3>
                <p>
                Looking for a dessert wine that's anything but ordinary? Look no further than our Moscato - sparkling, sweet, fizzy, and perfectly smooth, it's the perfect indulgence. For the best experience, serve it chilled and savour the unique and delicious taste. At EZCAPE WINE, we believe that bubbles don't have to be boring - and we think you'll agree once you've tried our Moscato

                </p>
                <a href="./products" className="cmn-btn">
                  Shop now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="title2 text-center">
                <h3>our Blogs</h3>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-wrap">
                <div className="blog-picture position-relative">
                  <div className="date">
                    <span className="day"> 24</span>
                    <span className="month">Jan</span>
                  </div>
                  <a href="#">
                    <img src="./assets/images/blog.png" alt="blog" />
                  </a>
                </div>
                <div className="info-blog text-center">
                  <a className="blog-cate" href="">
                    News
                  </a>
                  <h4 className="title-blog">
                    <a href="">A Lesson In Wines</a>
                  </h4>
                  <p className="content mb-0">
                    Where can I get some? Etiam risus diam, porttitor vitae
                    ultrices quis, dapibus id dolor.Morbi venenatis lacinia
                    rhoncus.Lorem ipsum dolor sit amet,...
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-wrap">
                <div className="blog-picture position-relative">
                  <div className="date">
                    <span className="day"> 24</span>
                    <span className="month">Jan</span>
                  </div>
                  <a href="#">
                    <img src="./assets/images/blog.png" alt="blog" />
                  </a>
                </div>
                <div className="info-blog text-center">
                  <a className="blog-cate" href="">
                    News
                  </a>
                  <h4 className="title-blog">
                    <a href="">A Lesson In Wines</a>
                  </h4>
                  <p className="content mb-0">
                    Where can I get some? Etiam risus diam, porttitor vitae
                    ultrices quis, dapibus id dolor.Morbi venenatis lacinia
                    rhoncus.Lorem ipsum dolor sit amet,...
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-wrap">
                <div className="blog-picture position-relative">
                  <div className="date">
                    <span className="day"> 24</span>
                    <span className="month">Jan</span>
                  </div>
                  <a href="#">
                    <img src="./assets/images/blog.png" alt="blog" />
                  </a>
                </div>
                <div className="info-blog text-center">
                  <a className="blog-cate" href="">
                    News
                  </a>
                  <h4 className="title-blog">
                    <a href="">A Lesson In Wines</a>
                  </h4>
                  <p className="content mb-0">
                    Where can I get some? Etiam risus diam, porttitor vitae
                    ultrices quis, dapibus id dolor.Morbi venenatis lacinia
                    rhoncus.Lorem ipsum dolor sit amet,...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="title2 text-center">
                <h3>OUR SATISFIED CUSTOMERSS</h3>
              </div>
            </div>
            
            <section id="testimonials">

            <div className="col-lg-12">
              <div className="best-seller-slider">
        <Slider {...reviews}>
        <div className="testimonial-box-container">
            <div className="testimonial-box">
                
                <div className="box-top">
                    
                    <div className="profile">
                        
                        <div className="profile-img">
                            <img src="./assets/images/man_1.jpeg" />
                        </div>
                        
                        <div className="name-user">
                            <strong>KIM</strong>
                            <span>---------</span>
                        </div>
                    </div>
                    
                    <div className="reviews">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="far fa-star"></i>
                    </div>
                </div>
                
                <div className="client-comment">
                    <p>Wow What A Great Wine, I Love It EZCAPE WINE Is Now On My Summer List!</p>
                </div>
            </div>
            </div>
            <div className="testimonial-box-container">
            <div className="testimonial-box">
                
                <div className="box-top">
                    
                    <div className="profile">
                        
                        <div className="profile-img">
                        <img src="./assets/images/man_2.jpeg" />
                        </div>
                        
                        <div className="name-user">
                            <strong>Noah</strong>
                            <span>----------</span>
                        </div>
                    </div>
                    
                    <div className="reviews">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                    </div>
                </div>
                
                <div className="client-comment">
                    <p>Power Steering Reservoir
                    EZCAPE WINE Is Truly An Upstanding Company And Is Behind Their Product 100%. Highly Recommend.
                    </p>
                </div>
            </div>
            </div>
            <div className="testimonial-box-container">
            <div className="testimonial-box">
                
                <div className="box-top">
                    
                    <div className="profile">
                        
                        <div className="profile-img">
                        <img src="./assets/images/man_3.jpeg" />
                        </div>
                        
                        <div className="name-user">
                            <strong>Cheyenne</strong>
                            <span>----------</span>
                        </div>
                    </div>
                    
                    <div className="reviews">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="far fa-star"></i>
                    </div>
                </div>
                
                <div className="client-comment">
                    <p>Thank You! Product Was As Described, Shipped On Time, And Made a Great Gift!</p>
                </div>
            </div>
            </div>
            <div className="testimonial-box-container">
            <div className="testimonial-box">
                
                <div className="box-top">
                    
                    <div className="profile">
                        
                        <div className="profile-img">
                        <img src="./assets/images/man_4.jpeg" />
                        </div>
                        
                        <div className="name-user">
                            <strong>Angel Segar</strong>
                            <span>----------</span>
                        </div>
                    </div>
                    
                    <div className="reviews">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="far fa-star"></i>
                    </div>
                </div>
                
                <div className="client-comment">
                    <p>Great!
                    I had the Peach Mango and baby!!! It’s a very smooth drink and the taste is exceptional!! I will be ordering again very soon. Everyone loved it and I guarantee you will too
                    </p>
                </div>
            </div>
            </div>    
            </Slider>
            </div>
            </div>
        
    </section>
          </div>
        </div>
      </section>
      
        <div>

            <img
                            src='./assets/images/footerbanner.png'
                          />
                          </div>
      
      <section className="as-seen-on-you p-70">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="title text-center">
                <h3>As seen on you</h3>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="as-seenyou-img position-relative">
                <img src="./assets/images/instagram1.png" alt="insta" />
                <div className="content-instagram">
                  <a href="#" className="fab fa-instagram-square"></a>
                  <h4 className="mb-0">Shop it</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="as-seenyou-img position-relative">
                    <img src="./assets/images/instagram2.png" alt="insta" />
                    <div className="content-instagram">
                      <a href="#" className="fab fa-instagram-square"></a>
                      <h4 className="mb-0">Shop it</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="as-seenyou-img position-relative">
                    <img src="./assets/images/instagram3.png" alt="insta" />
                    <div className="content-instagram">
                      <a href="#" className="fab fa-instagram-square"></a>
                      <h4 className="mb-0">Shop it</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 mt-4">
                  <div className="as-seenyou-img position-relative">
                    <img src="./assets/images/instagram4.png" alt="insta" />
                    <div className="content-instagram">
                      <a href="#" className="fab fa-instagram-square"></a>
                      <h4 className="mb-0">Shop it</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 mt-4">
                  <div className="as-seenyou-img position-relative">
                    <img src="./assets/images/instagram5.png" alt="insta" />
                    <div className="content-instagram">
                      <a href="#" className="fab fa-instagram-square"></a>
                      <h4 className="mb-0">Shop it</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center text_bottom">
              <span className="icon-top">
                <i className="fab fa-instagram-square"></i>
              </span>
              <h3 className=" text-center mb-0">Follow us on Instagram</h3>
            </div>
          </div>
        </div>
      </section>
      <div>
      <img
                            src='./assets/images/bottombanner.png'
                          />
                          </div>
      
    </Fragment>
  );
};

export default Home;
