import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AddProductUserLike, AddToCart, GetCartProduct } from "../../Firebase";
import { setopenLoginModule } from "../../Redux/Authentication/Login";
import { addtoCart } from "../../Redux/Products/ProductManage";
import { RootState } from "../../Redux/Store";
import { Product, User } from "../../types";

const ProductItem = (props: any) => {
  const { productInfo } = props;

  const dispatch = useDispatch()
  const userinfo: User = useSelector((state: RootState) => state.login.user);
 
  function addToBag() {
    if(JSON.stringify(userinfo) === "{}"){
      dispatch(setopenLoginModule(true));
      return;
    }
    AddToCart(userinfo.uid, productInfo.Id, 1, (Number(productInfo.Original_price) * 1), () => {
      console.log('cartt', 'done');
      GetCartProduct(userinfo.uid, data => {
        dispatch(addtoCart(data));
      })
    })
  }

  const likeProduct = () => {
    if(JSON.stringify(userinfo) === "{}"){
      dispatch(setopenLoginModule(true));
      return;
    }
    AddProductUserLike(userinfo.uid, productInfo.Id, true, () => {
    
    })
  }

  return (
    <Link to="/product-detail">
      <div className="best-seller-wrap text-center">
        <div className="seller-img-product position-relative">
        
            <img src={productInfo['Images'][0]} alt="product" />
          
          <ul className="best-seller-products p-0 m-0">
            <li>
              <a href="#" tabIndex={-1} onClick={() => addToBag()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 297.78668 398.66666"
                  height="398.66666"
                  width="297.78668"
                  id="svg2"
                  version="1.1"
                >
                  <defs id="defs6"></defs>
                  <g
                    transform="matrix(1.3333333,0,0,-1.3333333,0,398.66667)"
                    id="g10"
                  >
                    <g transform="scale(0.1)" id="g12">
                      <path
                        id="path14"
                        d="M 2233.36,2432.71 H 0 V 0 h 2233.36 v 2432.71 z m -220,-220 V 220 H 220.004 V 2212.71 H 2021.36"
                      ></path>
                      <path
                        xmlns="http://www.w3.org/2000/svg"
                        id="path16"
                        d="m 1116.68,2990 v 0 C 755.461,2990 462.637,2697.18 462.637,2335.96 V 2216.92 H 1770.71 v 119.04 c 0,361.22 -292.82,654.04 -654.03,654.04 z m 0,-220 c 204.58,0 376.55,-142.29 422.19,-333.08 H 694.492 C 740.117,2627.71 912.102,2770 1116.68,2770"
                      ></path>
                      <path
                        xmlns="http://www.w3.org/2000/svg"
                        id="path18"
                        d="M 1554.82,1888.17 H 678.543 v 169.54 h 876.277 v -169.54"
                      ></path>
                    </g>
                  </g>
                </svg>
              </a>
            </li>
            
            <li>
              <a href="#" tabIndex={-1} onClick={() => likeProduct()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 400 400"
                  height="400"
                  width="400"
                  id="svg2"
                  version="1.1"
                >
                  <defs id="defs6"></defs>
                  <g
                    transform="matrix(1.3333333,0,0,-1.3333333,0,400)"
                    id="g10"
                  >
                    <g transform="scale(0.1)" id="g12">
                      <path
                        id="path14"
                        d="m 903,2424.4 c 157.9,0 306.4,-61.5 418.1,-173.1 l 134.8,-134.9 c 20.7,-20.6 48.1,-32 77.1,-32 29,0 56.4,11.4 77,32 l 133.7,133.7 c 111.7,111.6 259.9,173.1 417.5,173.1 156.91,0 305,-61.3 416.8,-172.5 111.2,-111.3 172.5,-259.5 172.5,-417.5 0.6,-157.3 -60.69,-305.5 -172.5,-417.4 L 1531.5,373.5 487.402,1417.6 c -111.601,111.7 -173.105,259.9 -173.105,417.5 0,158.1 61.199,306.1 172.5,416.8 111.308,111.2 259.101,172.5 416.203,172.5 z m 1829.7,-19.6 c 0,0 0,0 -0.1,0 -152.4,152.4 -355.1,236.3 -570.9,236.3 -215.7,0 -418.7,-84.1 -571.5,-236.9 l -56.9,-57 -58.2,58.2 c -153.1,153.1 -356.3,237.5 -572.1,237.5 -215.305,0 -417.902,-83.9 -570.305,-236.3 -153,-153 -236.8942,-356 -236.2966,-571.5 0,-215 84.4026,-417.8 237.4966,-571 L 1454.7,143.301 c 20.5,-20.403 48.41,-32.199 76.8,-32.199 28.7,0 56.7,11.5 76.7,31.597 L 2731.5,1261.8 c 152.7,152.7 236.8,355.7 236.8,571.4 0.7,216 -83,419 -235.6,571.6"
                      ></path>
                    </g>
                  </g>
                </svg>
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h4>
            <a href="">{(productInfo as Product).Name}</a>{" "}
          </h4>
          <p>
            <s className="price-old">${(productInfo as Product).Original_price}</s>
            <span className="price"> ${(productInfo as Product).Current_price}</span>
          </p>
        </div>
      </div>
    </Link>
  );
};

export default ProductItem;
