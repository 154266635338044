import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddProductUserLike, deleteMyCart, ProductUserLike } from "../../Firebase";
import { RemoveToMyCart } from "../../Redux/Products/ProductManage";
import { RootState } from "../../Redux/Store";
import { CartModel, Product, User } from "../../types";

const WishList = (props: any) => {
  const { open, close } = props;
  const dispatch = useDispatch()
  const userinfo: User = useSelector((state: RootState) => state.login.user);
  const allProducts: Array<Product> = useSelector((state: RootState) => state.product.products);
  const [wishList, setWishList] = useState<Array<Object>>(Array<Object>());
  const [total, setTotal] = useState(0);
  function closeModal() {
    close();
  }

  useEffect(() => {
    getLikeProduct();
  }, [allProducts])


  const getLikeProduct = () => {
    ProductUserLike(userinfo.uid, data => {
      setWishList(data);
      
    })
  }

  const likeProduct = (id: string) => {
    AddProductUserLike(userinfo.uid, id, false, () => {
      console.log('falsee', 'faf');
      
      getLikeProduct();
    })
  }

  return (
    <Fragment>
      <div className={open ? "minicart active" : "minicart"}>
        <div className="mini-cart-head position-relative">
          <a
            href="javascript:void(0)"
            className="mini-cart-undo close-mini-cart"
            onClick={closeModal}
          >
            <i className="fas fa-times"></i>
          </a>
          <h3 className="title">Wish List</h3>
          <div className="mini-cart-counter">
            <span className="cart-counter enj-cartcount">{wishList.length}</span>
          </div>
        </div>
        {wishList.length > 0 ? (
          <div className="mini-cart-bottom">
            <div className="prod">
              <div className="product-cart">
                <ul className="minicart_item list-unstyled">
                  {
                    wishList.map((like, index) => {
                      let item = allProducts.find(i => i.Id === JSON.parse(JSON.stringify(like))['p_id']);
                      return item ? (
                      <li key={index}>
                        <div className="thumb_img_cartmini">
                          <a href="#">
                            <img src={item?.Images[0]} alt="" />
                          </a>
                        </div>
                        <div className="product-detail">
                          <h6 className="product_name_mini" style={{width: '90%'}}>
                            <a href="/products/blanc-de-blancs?variant=41409308721340">
                              {item?.Name}
                            </a>
                          </h6>
                          <div className="product_info">
                            <div className="product_price">
                              <span className="price_sale">${item?.Original_price}.00</span>
                            </div>
                          </div>
                        </div>
                        <div className="produc_remove">
                          <a className="p-2" onClick={() => likeProduct((item as Product).Id)}>
                            <svg id="Layer_1" height="20" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 107.39"><defs></defs><path className="cls-1" d="M60.83,17.18c8-8.35,13.62-15.57,26-17C110-2.46,131.27,21.26,119.57,44.61c-3.33,6.65-10.11,14.56-17.61,22.32-8.23,8.52-17.34,16.87-23.72,23.2l-17.4,17.26L46.46,93.55C29.16,76.89,1,55.92,0,29.94-.63,11.74,13.73.08,30.25.29c14.76.2,21,7.54,30.58,16.89Z" fill="red"/></svg>
                          </a>
                        </div>
                    </li>    
                    ) : <></>}
                    )
                  }
                  
                </ul>
              </div>
            </div>
            
            <div className="action-checkout">
              <a href="./cart" className="button-viewcart" style={{width: '100%'}}>
                <span>View cart</span>
              </a>
            </div>
          </div>
        ) : (
          <div>
            <div className="empty-product_minicart empty">
              <p className="mb-0">Your WishList is empty</p>
              <a href="/collections/all" className="to-cart">Go to the shop</a>
            </div>
          </div>
        )}
      </div>
      <div
        className={open ? "bg_search_box active" : "bg_search_box "}
        onClick={closeModal}
      ></div>
    </Fragment>
  );
};

export default WishList;
