

import { configureStore } from '@reduxjs/toolkit'
import  LoginSlice from './Authentication/Login'
import  ProductSlice from './Products/ProductManage'

export const store = configureStore({
    reducer: {
        login: LoginSlice,
        product: ProductSlice
    }
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch