import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteMyCart, GetCartProduct, updateCart } from "../../Firebase";
import { setopenLoginModule } from "../../Redux/Authentication/Login";
import { addtoCart, RemoveToMyCart } from "../../Redux/Products/ProductManage";
import { RootState } from "../../Redux/Store";
import { CartModel, User } from "../../types";

const Cart = () => {
  const dispatch = useDispatch()
  const userinfo: User = useSelector((state: RootState) => state.login.user);
  const MyCartDetail: Array<CartModel> = useSelector((state: RootState) => state.product.cart);
  const [cartData, setCartData] = useState(Array<CartModel>())
  const [total, setTotal] = useState(0);
  let cartTotal = 0;


  const GetCartData = () => {
    
      setCartData(MyCartDetail);
      cartTotal = 0;
      MyCartDetail.map(car => {
        cartTotal += Number(car.Product?.Current_price) * car.Quantity
      })
      setTotal(cartTotal);
    
  }

  const updateQuantity = (item: CartModel, type: string) => {
    if(JSON.stringify(userinfo) === "{}"){
      dispatch(setopenLoginModule(true));
      return;
    }
      let tempCartData = cartData.concat();
      let findCart = JSON.parse(JSON.stringify(tempCartData.find(car => car === item)));


      if(findCart){
        if(type === 'decrease'){
          findCart.Quantity > 1 ? findCart.Quantity-=1 : findCart.Quantity=1
        } else {
          findCart.Quantity = findCart.Quantity+1
        }
        if(item.Product){
          findCart.Price = Number(item.Product.Original_price) * findCart.Quantity;
        }
        
        updateCart(findCart, userinfo.uid, () => {
          GetCartProduct(userinfo.uid, data => {
            dispatch(addtoCart(data));
            setCartData(data); 
          })
        })
        
      }

  }

  useEffect(() => {
    GetCartData();
  }, [MyCartDetail])


  const deleteFromCart = (item: CartModel) => {
    if(JSON.stringify(userinfo) === "{}"){
      dispatch(setopenLoginModule(true));
      return;
    }
    deleteMyCart(item, userinfo.uid, () => {
        dispatch(RemoveToMyCart(item));
    })
  }

  return (
    <Fragment>
      <div className="wrap-bread-crumb breadcrumb_cart">
        <div className="container container-v1">
          <div className="bread-crumb">
            <a href="/" title="Back to the frontpage">
              Home<i className="fa fa-angle-right" aria-hidden="true"></i>
            </a>
            <strong>Your Shopping Cart</strong>
          </div>
        </div>
      </div>

      <section className="p-70">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <table>
                <thead>
                  <tr className="cart-title">
                    <th colSpan={2} className="product-thumbnail">
                      {" "}
                      Product name
                    </th>
                    <th className="product-price">Price</th>
                    <th className="product-quantity">Quantity</th>
                    <th className="product-subtotal">Total</th>
                    <th className="product-remove">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    cartData.map((item, index) => 
                      <tr className="cart_item" key={index}>
                      <td className="product-thumbnail">
                        
                          <img src={item.Product?.Images[0]} alt="" className="responsive_cart"/>
                        
                      </td>
                      <td className="product-name-thumb">
                        {item.Product?.Name}
                      </td>
                      <td className="product-price">
                        <span className="amount">${item.Product?.Current_price}</span>
                      </td>
                      <td className="product-price">
                        <div className="js-qty">
                          <button
                            type="button"
                            className="qty_minus js-qty__adjust js-qty__adjust--minus icon-fallback-text"
                            data-id=""
                            data-qty="0"
                            onClick={() => updateQuantity(item, 'decrease')}
                          >
                            <span className="fa fa-caret-down"></span>
                          </button>
                          <form>
                          <input
                            type="text"
                            className="js-qty__num"
                            value={item.Quantity}
                            min="1"
                            disabled
                            style={{textAlign:'left'}}
                            data-id=""
                            onChange={() => {}}
                            aria-label="quantity"
                            pattern="[0-9]*"
                            name="quantity"
                            id="Quantity"
                          />
                          </form>
                          <button
                            type="button"
                            className="qty_plus js-qty__adjust js-qty__adjust--plus icon-fallback-text"
                            data-id=""
                            data-qty="11"
                            onClick={() => updateQuantity(item, 'increment')}
                          >
                            <span className="fa fa-caret-up"></span>
                          </button>
                        </div>
                      </td>
                      <td className="product-subtotal">
                        <span className="amount">${Number(item.Product?.Current_price) * item.Quantity}</span>
                      </td>
                      <td className="product-remove">
                        <div className="remove set-12-svg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            id="Capa_1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 298.667 298.667"
                            onClick={() => deleteFromCart(item)}
                          >
                            {" "}
                            <g>
                              {" "}
                              <g>
                                {" "}
                                <polygon points="298.667,30.187 268.48,0 149.333,119.147 30.187,0 0,30.187 119.147,149.333 0,268.48 30.187,298.667     149.333,179.52 268.48,298.667 298.667,268.48 179.52,149.333   "></polygon>{" "}
                              </g>{" "}
                            </g>{" "}
                          </svg>
                        </div>
                      </td>
                      </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-lg-12">
              <div className="cart_totals">
                <div className="cart-update">
                  <input
                    type="submit"
                    value="Update Cart"
                    name="update_cart"
                    className="button bg-color"
                  />
                </div>
                <div className="continue-shopping-1">
                  <a href="#">Continue Shopping</a>
                </div>
              </div>
              <div className="cart-check">
                <h2 className="cart-title">CART TOTALS</h2>
                <table className="total-checkout">
                  <tbody>
                    <tr>
                      <th className="cart-label">
                        <span>Total</span>
                      </th>
                      <td className="cart-amount">
                        <span>
                          <strong>
                            <span className="amount">${total}.00</span>
                          </strong>{" "}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="wc-proceed-to-checkout clearfix">
                  <a
                    className="checkout-button button alt wc-forward bg-color"
                    href="/checkout"
                  >
                    Proceed to checkout
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Cart;
