import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetCartProduct, getDefaultAddress, MyOrder, updateEmail, userAccount } from "../../Firebase";
import { ClearLogout } from "../../Redux/Authentication/Login";
import { RootState } from "../../Redux/Store";
import { Address, CartModel, User, UserAccount } from "../../types";

const Profile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userinfo: User = useSelector((state: RootState) => state.login.user);
    const [myAccount, setMyAccount] = useState<UserAccount>();
    const [address, setAddeess] = useState<Address>();
    const [orders, setOrder] = useState<Array<any>>();
    const [cart, setCart] = useState<Array<CartModel>>();
    const [email, setEmail] = useState('');
    

    useEffect(() => {
        userAccount(userinfo.uid, data => {
          setEmail(data.Email)
            setMyAccount(data);
        })
        getDefaultAddress(userinfo.uid, data => {
          setAddeess(data);
        })
        MyOrder(userinfo.uid, data => { 
          setOrder(data);
        })
        GetCartProduct(userinfo.uid, data=>{
          setCart(data);
        })
    }, [userinfo])

    const logout = () => {
      localStorage.removeItem('user')
      localStorage.removeItem('isLogin')
      localStorage.removeItem('address')
      dispatch(ClearLogout);
      navigate('../');
      window.location.reload();
    }

    const emailSubmit = () => {
      if(email){
      updateEmail(userinfo.uid, email, () => {

      })
    }
    }

    return (
      <Fragment>
        <section className="profile">
  <header className="header row">
    <div className="details">
      <h1 className="">{myAccount?.Name}</h1>
      <h1 className="heading">{myAccount?.Mobileno}</h1>
      
      
      <input
        type="email"
        className="form-control"
        placeholder="Not Found Email"
        style={{backgroundColor: 'transparent', color: 'white', height: 10}}
        value={email} onChange={e => setEmail(e.target.value)}
        onBlur={() => emailSubmit()}
      />
      <h1 className="heading">{myAccount?.Date}</h1>
      <div className="location m-3">
        <svg width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
  <path d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12 ,2Z"></path>
</svg>
        <p>{address?.State}, {address?.Country}</p>
      </div>
      <div className="stats">
        <div className="col-4">
          <h4>{orders?.length}</h4>
          <p>MyOrders</p>
        </div>
        <div className="col-4">
          <h4>{cart?.length}</h4>
          <p>MyCart</p>
        </div>
      </div>
      <button
        className="shop-button enj-add-to-cart-btn engoj-btn-addtocart m-4"
        value="Logout"
        onClick={() => logout()}
      >
        Logout
      </button>
    </div>
  </header>
</section>
      </Fragment>
    );
  };
  
  export default Profile;