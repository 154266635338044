import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./Component/Footer/Footer";
import Header from "./Component/Header/Header";
import About from "./Pages/About/About";
import BlogDetail from "./Pages/Blog/BlogDetail";
import BlogList from "./Pages/Blog/BlogList";
import Cart from "./Pages/Cart/Cart";
import Checkout from "./Pages/Cart/Checkout";
import Contact from "./Pages/Contact/Contact";
import Home from "./Pages/Home/Home";
import Products from "./Pages/Product/Products";
import PurchaseSuccess from "./Pages/Product/PurchaseSuccess";
import ProductDetail from "./Pages/ProductDetail/ProductDetail";
import Profile from "./Pages/Profile/Profile";
import TermsOfServicepolicy from "./Pages/Privacy/Termsofservice";
import Shippingpolicy from "./Pages/Privacy/Shippingpolicy";
import Refundpolicy from "./Pages/Privacy/Refundpolicy";
import Privacypolicy from "./Pages/Privacy/Privacypolicy";

function App() {
  
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/Home" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/products" element={<Products />}></Route>
          <Route path="/product-detail" element={<ProductDetail />}></Route>
          <Route path="/cart" element={<Cart />}></Route>
          <Route path="/checkout" element={<Checkout />}></Route>
          <Route path="/PurchaseSuccess" element={<PurchaseSuccess />}></Route>
          <Route path="/Profile" element={<Profile />}></Route>
          <Route path="/blog" element={<BlogList />}></Route>
          <Route path="/blogdetail" element={<BlogDetail />}></Route>
          <Route path="/contact" element={<Contact />}></Route>

          <Route path="/Privacypolicy" element={<Privacypolicy />}></Route>
          <Route path="/Refundpolicy" element={<Refundpolicy />}></Route>
          <Route path="/Shippingpolicy" element={<Shippingpolicy />}></Route>
          <Route path="/TermsOfServicepolicy" element={<TermsOfServicepolicy />}></Route>
          
        </Routes>
        <Footer />
      </Router>
      <div id={'recaptcha-container'} />
    </>
  );
}

export default App;
