import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddToCart, deleteMyCart, GetCartProduct } from "../../Firebase";
import { addtoCart, RemoveToMyCart } from "../../Redux/Products/ProductManage";
import { RootState } from "../../Redux/Store";
import { CartModel, User } from "../../types";

const ShoppingCart = (props: any) => {
  const { open, close } = props;
  const dispatch = useDispatch()
  const userinfo: User = useSelector((state: RootState) => state.login.user);
  const MyCartDetail: Array<CartModel> = useSelector((state: RootState) => state.product.cart);
  const [shopList, setShopList] = useState([]);
  const [total, setTotal] = useState(0);
  function closeModal() {
    close();
  }

  useEffect(() => {
    let subTotal = 0;
    MyCartDetail.map(item => {
      subTotal+= Number(item.Product?.Current_price) * item.Quantity
    })
    subTotal += (subTotal * 8) / 100
    setTotal(subTotal)
  }, [MyCartDetail])

  const deleteFromCart = (item: CartModel) => {
    deleteMyCart(item, userinfo.uid, () => {
      GetCartProduct(userinfo.uid, data => {
        dispatch(addtoCart(data)); 
      })
    })
  }


  return (
    <Fragment>
      <div className={open ? "minicart active" : "minicart"}>
        <div className="mini-cart-head position-relative">
          <a
            href="javascript:void(0)"
            className="mini-cart-undo close-mini-cart"
            onClick={closeModal}
          >
            <i className="fas fa-times"></i>
          </a>
          <h3 className="title">Shopping Cart</h3>
          <div className="mini-cart-counter">
            <span className="cart-counter enj-cartcount">{MyCartDetail.length}</span>
          </div>
        </div>
        {MyCartDetail.length > 0 ? (
          <div className="mini-cart-bottom">
            <div className="prod">
              <div className="product-cart">
                <ul className="minicart_item list-unstyled">
                  {
                    MyCartDetail.map((item, index) => 
                      <li key={index}>
                        <div className="thumb_img_cartmini">
                          <a href="#">
                            <img src={item.Product?.Images[0]} alt="" />
                          </a>
                        </div>
                        <div className="product-detail">
                          <h3 className="product_name_mini">
                            <a href="/products/blanc-de-blancs?variant=41409308721340">
                              {item.Product?.Name}
                            </a>
                          </h3>
                          <div className="product_info">
                            <div className="product_quanity">QTY : {item.Quantity} </div>
                            <div className="product_price">
                              <span className="price_sale">${item.Product?.Current_price}</span>
                            </div>
                          </div>
                        </div>
                        <div className="produc_remove">
                          <a href="#" onClick={() => deleteFromCart(item)}>
                            <i className="fas fa-trash-alt"></i>
                          </a>
                        </div>
                    </li>    
                    )
                  }
                  
                </ul>
              </div>
            </div>
            <div className="sub-total">
              <span className="total-title float-left">Total:</span>
              <span className="total-price float-right">${total}.00</span>
            </div>
            <div className="action-checkout">
              <a href="./cart" className="button-viewcart">
                <span>View cart</span>
              </a>
              <a href="./checkout" className="button-checkout">
                <span>Check out</span>
              </a>
            </div>
          </div>
        ) : (
          <div>
            <div className="empty-product_minicart empty">
              <p className="mb-0">Your shopping bag is empty</p>
              <a href="/products" className="to-cart">Go to the shop</a>
            </div>
          </div>
        )}
      </div>
      <div
        className={open ? "bg_search_box active" : "bg_search_box "}
        onClick={closeModal}
      ></div>
    </Fragment>
  );
};

export default ShoppingCart;
