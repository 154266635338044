import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Address, User } from '../../types'

export const LoginSlice = createSlice({
    name: 'login',
    initialState: {
        user: JSON.parse(localStorage.getItem("user")?? JSON.stringify({} as User)),
        isLogin: localStorage.getItem("isLogin") ?? false,
        address: JSON.parse(localStorage.getItem("address")?? JSON.stringify({} as Address)),
        loader: false,
        openLoginModule: false,
    },
    reducers: {
        user: (state, action: PayloadAction<User>) => {
            state.user = action.payload
            localStorage.setItem("user", JSON.stringify(action.payload))
        },
        isLogin: (state, action: PayloadAction<boolean>) => {
            state.isLogin = action.payload
            localStorage.setItem("isLogin", JSON.stringify(action.payload))
        },
        setopenLoginModule: (state, action: PayloadAction<boolean>) => {
            console.log('openLoginModule---', action.payload);
            state.openLoginModule = action.payload
        },
        setLoader: (state, action: PayloadAction<boolean>) => {
            state.loader = action.payload
        },
        DefaultAddress: (state, action: PayloadAction<Address>) => {
            state.address = action.payload
            localStorage.setItem("address", JSON.stringify(action.payload))
        },
        ClearLogout: (state, action: PayloadAction<null>) => {
            state.user = null;
            state.isLogin = false;
            state.address = null;
        }
    }
})
export const  {user, isLogin, setLoader, DefaultAddress, ClearLogout, setopenLoginModule} = LoginSlice.actions
export default LoginSlice.reducer

