import { PaymentUrlResponse } from './../../types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CartModel, Category, Product } from '../../types'

export const ProductSlice = createSlice({
    name: 'products',
    initialState: {
        products: JSON.parse(localStorage.getItem("product")?? JSON.stringify(Array<Product>())),
        categorys: JSON.parse(localStorage.getItem("category")?? JSON.stringify(Array<Category>())),
        selectProduct: JSON.parse(localStorage.getItem("selectProduct")?? JSON.stringify({} as Product)),
        cart: JSON.parse(localStorage.getItem("myCart")?? JSON.stringify(Array<CartModel>())),
        paymentResponse: JSON.parse(localStorage.getItem("paymentResponse")?? JSON.stringify({} as PaymentUrlResponse)),
        comeAfterPayment: JSON.parse(localStorage.getItem("comeAfterPayment")?? JSON.stringify(false)),
    },
    reducers: {
        setProducts: (state, action: PayloadAction<Array<Product>>) => {
            state.products = action.payload
            localStorage.setItem("product", JSON.stringify(action.payload))
        },
        setCategorys: (state, action: PayloadAction<Array<Category>>) => {
            state.categorys = action.payload
            localStorage.setItem("category", JSON.stringify(action.payload))
        },
        setSelectProduct: (state, action: PayloadAction<Product>) => {
            state.selectProduct = action.payload
            localStorage.setItem("selectProduct", JSON.stringify(action.payload))
        },
        addtoCart: (state, action: PayloadAction<Array<CartModel>>) => {
            state.cart = action.payload
            localStorage.setItem("myCart", JSON.stringify(action.payload))
        },
        RemoveToMyCart: (state, action: PayloadAction<CartModel>) => {
            let array = state.cart as Array<CartModel>
            let newArray = array.filter(item => item.id !== action.payload.id)
            state.cart = newArray
            localStorage.setItem("myCart", JSON.stringify(newArray))
        },
        setPaymentResponse: (state, action: PayloadAction<PaymentUrlResponse>) => {
            state.paymentResponse = action.payload
            localStorage.setItem("paymentResponse", JSON.stringify(action.payload))
        },
        setcomeAfterPayment: (state, action: PayloadAction<boolean>) => {
            state.comeAfterPayment = action.payload
            localStorage.setItem("comeAfterPayment", JSON.stringify(action.payload))
        },
    }
})
export const  {setProducts, setCategorys, setSelectProduct, addtoCart, RemoveToMyCart, setPaymentResponse, setcomeAfterPayment} = ProductSlice.actions
export default ProductSlice.reducer

