import React, { useState } from "react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { setContactUs } from "../../Firebase";
import { RootState } from "../../Redux/Store";
import { User } from "../../types";


var behindBars = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrow: false,
  navigtion: false,
};

var AboutInstagram = {
  infinite: true,
  speed: 300,
  loop: true,
  margin: 10,
  slidesToShow: 5,
  arrows: true,
  slidesToScroll: 1,
  autoplay: true,
  dots: false,
  autoplaySpeed: 3000,
};

const Contact = () => {
  const userinfo: User = useSelector((state: RootState) => state.login.user);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const submitContact = () => {
    let contactUS = {name, email, message};
    setContactUs(userinfo.uid, contactUS, () => {
      setName('');
      setEmail('');
      setMessage('');
    })
  }

  return (
    <Fragment>
      <section className="cmn-banner_con contact-us-banner" style={{backgroundSize: 'cover',
    backgroundPosition: 'center'}}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="banner-text text-center">
                  <div className="title-page">
                    {/* <h2 className="">Contact Us</h2> */}
                  </div>
                  {/* <div className="bread-crumb">
                    <a href="index.html">
                      Home<i className="fa fa-angle-right" aria-hidden="true"></i>
                    </a>
                    <strong>Contact Us</strong>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
      </section>

      <section className="contact-us-form py-5">
        <div className="container">
          <div className="row contact_top align-items-center">
            <div className="col-lg-6">
              <div className="info_left mt-5">
                <h2>contact information</h2>
                <div className="content">
                  <p>At Ezcape Wine, we value our customers and their experience with our brand. Please note that while our corporate headquarters is located in New York City at 1201 Broadway, Suite 600, we do not sell products from this location. If you're interested in visiting our headquarters, we kindly ask that you first reach out to our customer service team to schedule an appointment. This ensures that we can provide you with the best possible experience and address any questions or concerns you may have. Thank you for choosing Ezcape Wine as your preferred wine provider, and we look forward to serving you soon.</p>
                </div>
                <div className="button-wrapper">
                  <a href="mailto:help@example.com">wine@ezcapewine.com</a>
                </div>
                <div className="follow_us">
                  <h2>Follow Us</h2>
                  
                  	<ul className="social-link" style={{marginLeft:'48%'}}>
                      <li>
                        <a href=""><i className="fab fa-twitter"></i></a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/ezcape_wine/"><i className="fab fa-instagram"></i></a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/ezcapewine"><i className="fa fa-facebook"></i></a>
                      </li>
                      <li>
                        <a href=""><i className="fa fa-youtube"></i></a>
                      </li>
                  </ul>

                  
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <a className="image_map d-block" target="_blank" href="">
                <img src="./assets/images/ContactUsImage.jpg" />
              </a>
            </div>
            <div className="col-lg-6"></div>
          </div>
          <div className="row justify-content-center contact_bottom">
            <div className="col-lg-12">
              <div className="title2 text-center contact_title"><h3>contact form</h3></div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>Name <span>*</span></label>
                <input type="text" className="form-control" value={name} onChange={e => setName(e.target.value)} />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label> Email address <span>*</span></label>
                <input
                  type="email"
                  className="form-control"
                  value={email} onChange={e => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group">
                <label>Your Message <span>*</span></label>
                <textarea
                  name=""
                  id=""
                  rows={9}
                  className="form-control"
                  value={message} onChange={e => setMessage(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="col-lg-3 text-center">
              <button type="button" className="cmn-btn w-100 border-0 d-inline-block" onClick={submitContact}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </section>

    {/*  <section className="section-newsletter2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 newsletter_left">
              <div className="newsletter_content">
                <div className="title_newletter">
                  <h3 className="title_heading mb-0">
                    Our <strong>NEWSLETTER!</strong>
                  </h3>
                </div>
                <p className="mb-0 content">
                  It only takes a second to be the first to find out about our
                  latest news
                </p>
              </div>
            </div>
            <div className="col-lg-6 newletter_email">
              <form action="" className="needs-validation">
                <div className="form-group m-0">
                  <input
                    type="email"
                    name="EMAIL"
                    className="form-control"
                    placeholder="Your email address..."
                    required
                  />
                </div>
                <button className="btn" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
*/}
      <section className="about-instagram">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 p-0">
              <div className="about-instagram-slider">
                <Slider {...AboutInstagram}>
                  <div>
                    <div className="as-seenyou-img position-relative">
                      <img src="./assets/images/instagram2.png" alt="" />
                      <div className="content-instagram">
                        <a href="#" className="">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="as-seenyou-img position-relative">
                      <img src="./assets/images/instagram2.png" alt="" />
                      <div className="content-instagram">
                        <a href="#" className="">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="as-seenyou-img position-relative">
                      <img src="./assets/images/instagram2.png" alt="" />
                      <div className="content-instagram">
                        <a href="#" className="">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="as-seenyou-img position-relative">
                      <img src="./assets/images/instagram2.png" alt="" />
                      <div className="content-instagram">
                        <a href="#" className="">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="as-seenyou-img position-relative">
                      <img src="./assets/images/instagram2.png" alt="" />
                      <div className="content-instagram">
                        <a href="#" className="">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="as-seenyou-img position-relative">
                      <img src="./assets/images/instagram2.png" alt="" />
                      <div className="content-instagram">
                        <a href="#" className="">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Contact;
