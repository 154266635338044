import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteMyCart } from "../../Firebase";
import { RemoveToMyCart } from "../../Redux/Products/ProductManage";
import { RootState } from "../../Redux/Store";
import { CartModel, Category, User } from "../../types";

const Filter = (props: any) => {
  const { open, close } = props;
  const dispatch = useDispatch()
  const [price, setPrice] = useState('');
  const categories: Array<Category> = useSelector((state: RootState) => state.product.categorys); 
  function closeModal() {
    close();
  }



  return (
    <Fragment>
      <div className={open ? "minicart active" : "minicart"}>
        <div className="mini-cart-head position-relative">
          <a
            href="javascript:void(0)"
            className="mini-cart-undo close-mini-cart"
            onClick={closeModal}
          >
            <i className="fas fa-times"></i>
          </a>
          <h3 className="title">Shopping Cart</h3>
        </div>
        <div className="col-lg-12 position-relative filter-content-wrap p-2">
        
                <div className="list col-lg-12">
                  <div className="widget widget-category filter-category">
                    <div className="widget-title">
                      <h2>Categories</h2>
                    </div>
                    <div className="widget-content">
                      <ul className="categories-list">
                        {
                          categories.map(item => 
                            <li>
                              <a href="#">
                                <p>{item.Name}</p>
                              </a>
                          </li>    
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div className="widget widget-category filter-category">
                    <div className="widget-title">
                      <h2>Price</h2>
                    </div>
                    <div className="widget-content">
                      <ul className="list-none list-price">
                        <li className="cat-item">
                          <input type="checkbox" value="0-10-00" />
                          <a
                            title="0 - $10.00"
                            className=" "
                            onClick={() => setPrice('10-100')}
                          >
                            $10.00 - $100.00
                          </a>
                        </li>
                        <li className="cat-item">
                          <input type="checkbox" value="0-10-00" />
                          <a
                            title="0 - $10.00"
                            className=" "
                            onClick={() => setPrice('100-500')}
                          >
                            $100.00 - $500.00
                          </a>
                        </li>
                        <li className="cat-item">
                          <input type="checkbox" value="0-10-00" />
                          <a
                            title="0 - $10.00"
                            className=" "
                            onClick={() => setPrice('500-1000')}
                          >
                            $500.00 - $1000.00
                          </a>
                        </li>
                        <li className="cat-item">
                          <input type="checkbox" value="0-10-00" />
                          <a
                            title="0 - $10.00"
                            className=" "
                            onClick={() => setPrice('1000-5000')}
                          >
                            $1000.00 - $5000.00
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className="widget widget-category filter-category">
                    <div className="widget-title">
                      <h2>size</h2>
                    </div>
                    <div className="widget-content">
                      <ul className="list-none list-size">
                        <li>
                          <a href="" title="S" className=" ">
                            S
                          </a>
                        </li>
                        <li>
                          <a href="" title="M" className=" ">
                            M
                          </a>
                        </li>
                        <li>
                          <a href="" title="L" className=" ">
                            L
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  {/* <div className="widget widget-category filter-category">
                    <div className="widget-title">
                      <h2>color</h2>
                    </div>
                    <div className="widget-content">
                      <ul className="list-none list_color">
                        <li>
                          <input type="checkbox" value="beige" />
                          <a
                            href=""
                            title="Beige"
                            className=""
                          ></a>
                        </li>
                        <li>
                          <input type="checkbox" value="beige" />
                          <a
                            href=""
                            title="Beige"
                            className=""
                          ></a>
                        </li>
                        <li>
                          <input type="checkbox" value="beige" />
                          <a
                            href=""
                            title="Beige"
                            className=""
                          ></a>
                        </li>
                        <li>
                          <input type="checkbox" value="beige" />
                          <a
                            href=""
                            title="Beige"
                            className=""
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                   */}
                  <div className="widget widget-category filter-category">
                    <div className="widget-title">
                      <h2>BRAND</h2>
                    </div>
                    <div className="widget-content">
                      <ul className="categories-list">
                        <li>
                          <a href="#">
                            <p>Wineryn</p>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
      </div>
      <div
        className={open ? "bg_search_box active" : "bg_search_box "}
        onClick={closeModal}
      ></div>
    </Fragment>
  );
};

export default Filter;
