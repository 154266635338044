import { Fragment } from "react";

const Shippingpolicy = () => {

    return (
      <Fragment>
        <section>
            <div className="container-fluid">
              <div className="row">
              <h1 className="sr-only">Shipping Policy</h1>
<section>
  <div className="container">
    <div className="mb-50">
      <div className="maxus-titlebox">
        <div className="text-center">
          <h2 className="maxus-titlebox__title h1 font-300 fz-60 mb-5">Shipping Policy</h2>
          <h3 className="maxus-titlebox__meta text-uppercase h5  "></h3>
        </div>
      </div>
    </div>
  </div>
</section>

<section>
  <div className="container">
    {/* <meta charSet="utf-8"><meta charSet="utf-8"> */}
        <p data-sanitized-data-mce-fragment="1">*For all orders placed online Monday-Wednesday, please allow 1-2 business days to process your order before shipping.</p>
        <br/>
        <p data-sanitized-data-mce-fragment="1">*Any orders placed Thursday-Sunday, will be shipped the following Monday. Orders placed after 11:00pm to 5:00pm PST are scheduled to be processed the following business day. Again, please allow up to 2 business days to process your order before shipping.</p>
        <br/>
        <p data-sanitized-data-mce-fragment="1">*Delivery of the package depends on the distance from the winery and the day the order was received. Shipments for delivery on the West Coast typically take 1-3 business days to arrive, and shipments to the East Coast typically take 3-5 business days to arrive, weather permitted. Due to heat and storage during transportation, all orders are shipped Monday through Thursday and delivered Monday through Friday.</p>
        <br/>
        <p data-sanitized-data-mce-fragment="1">*Orders are shipped through UPS Ground. No expedited shipping is available. We do not offer International sales or shipping at this time.</p>
        <br/>
        <p data-sanitized-data-mce-fragment="1">*UPS cannot deliver wine to P.O. boxes, so please provide a physical address where someone 21 or older can sign for the wine.</p>
        <br/>
        <p data-sanitized-data-mce-fragment="1">*All packages containing alcohol must be signed for upon delivery by an adult (21 years of age or older). UPS will not leave such packages on the door step, even if the recipient has requested this. UPS will attempt to deliver packages containing alcohol 3 times before sending it back to Bel Vino Winery.</p>
        <br/>
        <p data-sanitized-data-mce-fragment="1">*Ezcape is not responsible for additional shipping charges. If a package returns, please contact us. A re-shipping fee will be applied to all orders that are sent back out.</p>
        <br/>
        <p data-sanitized-data-mce-fragment="1">*Please notify Ezcape Wine directly at the information below, if you should have any issues receiving your expected delivery. Shipping rates and package sizes vary depending on quantity of the order.</p>
        <br/>
        <div data-sanitized-data-mce-fragment="1">Ezcape Wine |&nbsp;<span>331 E Main Street, 234, Rock Hill South Carolina 29730, United States | ph.&nbsp;</span><span>866-393-9227 |&nbsp; e.&nbsp;<a data-encoded-attr-charset="dXRmLTg=" data-encoded-tag-value="" data-encoded-tag-name="meta"></a><a href="mailto:wine@ezcapewine.com" data-sanitized-data-mce-fragment="1" data-sanitized-data-mce-href="mailto:wine@ezcapewine.com">wine@ezcapewine.com</a></span>
        </div>
  {/* </meta>
  </meta> */}
  </div>
</section>

              </div>
            </div>
        </section>
  
       
      </Fragment>
    );
  };
  
  export default Shippingpolicy;
  