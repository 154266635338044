import moment from 'moment';
import { UserAccount, Category, Product, PromoCode, Review, CartModel, Address, Banner } from './../types';
import { initializeApp } from "firebase/app";
import { getStorage, ref as s_ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getDatabase, ref, set, push, onValue, remove } from "firebase/database";
import { getAuth,  signInWithPhoneNumber, RecaptchaVerifier, createUserWithEmailAndPassword, signInWithEmailAndPassword, ConfirmationResult} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAzlQBhbCwbM9tj2iEUosPVIW5GWMPsOMg",
    authDomain: "dbmanage-fbd05.firebaseapp.com",
    databaseURL: "https://dbmanage-fbd05-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "dbmanage-fbd05",
    storageBucket: "dbmanage-fbd05.appspot.com",
    messagingSenderId: "462370498019",
    appId: "1:462370498019:web:8389a18d368dcd0dc5acd6",
    measurementId: "G-YKVRDFY9DZ"
  };

export const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const defaultStorage = getStorage(app);
// const analytics = getAnalytics(app);
let confirmationResult: ConfirmationResult;
console.log('sdfsd', auth.currentUser?.uid);


export const checkWebIsVerify = (callback: (verify: RecaptchaVerifier) => unknown) => {
console.log('sdsddf');

  const reCaptchaVerify = new RecaptchaVerifier('recaptcha-container', {
  'size': 'invisible',
  'callback': () => {
    console.log('resolve');
    
    callback(reCaptchaVerify);
  },
  'expired-callback': () => {
    console.log('expire');
  }
  }, auth);
  reCaptchaVerify.render();
  reCaptchaVerify.verify();
}

export const phoneNumberAuth = (verify: RecaptchaVerifier, phoneNumber: string, callback: () => unknown) => {
    console.log('phoneoe', phoneNumber);
    
    signInWithPhoneNumber(auth, phoneNumber, verify).then((result) => {
        confirmationResult = result;
        callback();
    }).catch((err) => {
        console.log('redsd', err);
    });
}

export const ConfirmationOtpCode = (otp: string, callback: (userInfo: object) => unknown) => {
    console.log('otp', otp);
    confirmationResult.confirm(otp).then(async result => {
        const userInfo = {
            uid: result.user.uid,
            createTime: result.user.metadata.creationTime,
            lastSigninTime: result.user.metadata.lastSignInTime,
            phoneNumber: result.user.phoneNumber,
            getIdToken: await result.user.getIdToken(),
            refreshToken: result.user.refreshToken,
        }
        callback(userInfo);
    })
}

export const LoginFirebaseWineUser = async (email: string, pass: string) => {
    
    return signInWithEmailAndPassword(auth, email, pass).then(userCredential => {
        
        return userCredential.user
    }).catch(error => {
        return error
    }) 
}



export const CreateFirebaseWineUser = async (email: string, pass: string) => {
    // "wine.admin.com"
    return createUserWithEmailAndPassword(auth, email, pass).then(userCredential => {
        return userCredential.user
    }).catch(error => {
        // const errorcode = error.code;
        // const errorMessage = error.message
        return error.message;
    }) 
}


export const InsertAccount = async (user: UserAccount, callback: (data: string) => unknown) => {
    const userRef = ref(database, `WineDatabase/Account/${user.Uid}`);
    set(userRef, user).then(() => {
        callback('User Insert Successfully')
    });
}

export const userAccount = async (id: String, callback: (data: UserAccount) => unknown) => {
    const productRef = ref(database, `WineDatabase/Account/${id}`);
    onValue(productRef, (snapshot) => {
        callback(snapshot.val() as UserAccount)
    }, (cancel) => {
        console.log('cancel',cancel.message);
    })   
}

export const ReadProduct = async (callback: (data: Array<Product>) => unknown) => {
    const ProductList: Array<Product> = []
    const productRef = ref(database, 'WineDatabase/Products/');
    onValue(productRef, (snapshot) => {
        snapshot.forEach((child) => {
            const data = child.val()
            console.log('data------>', data);
            
            ProductList.push(data as Product)
        })
        
        callback(ProductList)
    }, (cancel) => {
        console.log('cancel',cancel.message);
    })     
}

export const ReadCategory = async (callback: (data: Array<Category>) => unknown) => {
    const CategoryList: Array<Category> = []
    const categoryRef = ref(database, 'WineDatabase/Category/');
    onValue(categoryRef, (snapshot) => {
        snapshot.forEach((child) => {
            const data = child.val()
            CategoryList.push(data as Category)
        })
        
        callback(CategoryList)
    }, (cancel) => {
        console.log('cancel',cancel.message);
    })     
}


export const AddProductReview = async (reviewData: any, productID: string, callback: () => unknown) => {
    const addReviewRef = push(ref(database));
    const newId = ref(database, `WineDatabase/Review/${productID}/${addReviewRef.key}`);
    return set(newId, {...reviewData, date: moment().format("DD-MMM-YYYY"), id: addReviewRef.key}).then(_ => callback)
}

export const ReadProductReview = async (productID: string, callback: (data: Array<Review>) => unknown) => {
    const ReviewList: Array<Review> = []
    const ReviewRef = ref(database, `WineDatabase/Review/${productID}`);
    onValue(ReviewRef, (snapshot) => {
        snapshot.forEach((child) => {
            const data = child.val()
            ReviewList.push(data as Review)
        })
        
        callback(ReviewList)
    }, (cancel) => {
        console.log('cancel',cancel.message);
    }) 
}

export const AddProductUserLike = async (UID: string, productID: string, isLike: boolean, callback: () => unknown) => {
    const newId = ref(database, `WineDatabase/Like/${UID}/${productID}`);
    return set(newId, isLike).then(() => callback())
}

export const isProductUserLike = async (UID: string, productID: string, callback: (isLike: boolean) => unknown) => {
    const productLikeRef = ref(database, `WineDatabase/Like/${UID}/${productID}`);
    onValue(productLikeRef, (snapshot) => {
        callback(snapshot.val())
    }, (cancel) => {
        console.log('cancel',cancel.message);
    })     
}

export const ProductUserLike = async (UID: string, callback: (likeProduct: Array<any>) => unknown) => {
    const LikeList: Array<any> = []
    const productLikeRef = ref(database, `WineDatabase/Like/${UID}`);
    onValue(productLikeRef, (snapshot) => {
        snapshot.forEach((child) => {
            const data = child.val()
            if(data) {LikeList.push({p_id: child.key, value: data})};
        })
        callback(LikeList);
    }, (cancel) => {
        console.log('cancel',cancel.message);
    })     
}


export const updateProductStock = async (products: Array<CartModel>, callback: () => unknown) => {
    products.map(item => {
        const productRef = ref(database, `WineDatabase/Products/${item.Product_Id}`);
        let stockProduct: Product = JSON.parse(JSON.stringify(item.Product))
        stockProduct.Stock = String(Number(item.Product?.Stock)-item.Quantity)
        set(productRef, {...stockProduct})
    })
    callback();
}

export const AddToCart = (uId: string, productId: string, quantity: number, price: number, callback: () => unknown) => {
    const addReviewRef = push(ref(database));
    const newId = ref(database, `WineDatabase/AddToCart/${uId}/${addReviewRef.key}`);
    set(newId, {id: addReviewRef.key, Product_Id: productId, Quantity: quantity, Price: price}).then(() => callback())  
}

export const GetCartProduct = async (uid: string, callback: (data: Array<CartModel>) => unknown) => {
    const CartList: Array<CartModel> = []
    const CartRef = ref(database, `WineDatabase/AddToCart/${uid}`);
    onValue(CartRef, (snapshot) => {
        snapshot.forEach((child) => {
            let data = child.val() as CartModel
            getProductDetail(data.Product_Id, pro => {
                CartList.push({...data, Product: pro});
            });
            
        })
        
        callback(CartList)
    }, (cancel) => {
        console.log('cancel',cancel.message);
    }) 
}

export const getProductDetail = (id: string, callback: (data: Product) => unknown) => {
    console.log('pid', id);
    const productRef = ref(database, `WineDatabase/Products/${id}`);
    onValue(productRef, (snapshot) => {
        const data = snapshot.val();
        callback(data as Product);
    }, (cancel) => {
        console.log('cancel',cancel.message);
    })     
}

export const updateCart = (cartmodel: CartModel, uId: string, callback: () => unknown) => {
    const newId = ref(database, `WineDatabase/AddToCart/${uId}/${cartmodel.id}`);
    let tempCart = JSON.parse(JSON.stringify(cartmodel))
    delete tempCart.Product
    set(newId, {...tempCart}).then(() => callback())  
}

export const deleteMyCart = async (cartmodel: CartModel, uId: string, callBack: () => unknown) => {
    const cId = ref(database, `WineDatabase/AddToCart/${uId}/${cartmodel.id}`);
    remove(cId).then(() => {
        callBack();
    })
}

export const clearCart = async (uId: string, callBack: () => unknown) => {
    const cId = ref(database, `WineDatabase/AddToCart/${uId}`);
    remove(cId).then(() => {
        callBack();
    })
}

export const addDefaultAddress = (uId: string, address: Address, callback: () => unknown) => {
    const addreddRef = push(ref(database));
    const newId = ref(database, `WineDatabase/Address/${uId}/${addreddRef.key}`);
    let tempaddress = JSON.parse(JSON.stringify(address))
    tempaddress.id= newId.key;
    set(newId, tempaddress).then(() => callback())  
}

export const getDefaultAddress = (uId: string, callback: (data: Address) => unknown) => {
    const productRef = ref(database, `WineDatabase/Address/${uId}`);
    onValue(productRef, (snapshot) => {

        snapshot.forEach((child) => {
            const data = child.val()
            if((data as Address).Active){
                callback(data as Address);
            }
            
        })
        
    }, (cancel) => {
        console.log('cancel',cancel.message);
    })     
}

export const getAllAddress = (uId: string, callback: (data: Array<Address>) => unknown) => {
    const productRef = ref(database, `WineDatabase/Address/${uId}`);
    let AddressList: Array<Address> = []
    onValue(productRef, (snapshot) => {
        AddressList = [];
        snapshot.forEach((child) => {
            const data = child.val()
            AddressList.push(data)
        })
        callback(AddressList);
    }, (cancel) => {
        console.log('cancel',cancel.message);
    })     
}

export const updateDefaultAddress = (uId: string, aID: string, isDefault: boolean, callback: () => unknown) => {
    const productRef = ref(database, `WineDatabase/Address/${uId}/${aID}/Active`);
    set(productRef,  isDefault).then(()=> callback());
       
}

export const setOrder = (uId: string, order: any, callback: () => unknown) => {
    const addOrderRef = push(ref(database));
    const newId = ref(database, `WineDatabase/Orders/${uId}/${addOrderRef.key}`);
    set(newId, {id: addOrderRef.key, ...order}).then(() => callback())  
}

export const addSubscribeUser = (Email: string, callback: () => unknown) => {
    const addSubscribeRef = push(ref(database));
    const newId = ref(database, `WineDatabase/Subscribe/${addSubscribeRef.key}`);
    set(newId, {id: addSubscribeRef.key, Email: Email}).then(() => callback())  
}


export const MyOrder = async (uID: string, callback: (data: Array<any>) => unknown) => {
    const MyOrderList: Array<any> = []
    const MyOrderRef = ref(database, `WineDatabase/Orders/${uID}`);
    onValue(MyOrderRef, (snapshot) => {
        snapshot.forEach((child) => {
            const data = child.val()
            MyOrderList.push(data)
        })
        
        callback(MyOrderList)
    }, (cancel) => {
        console.log('cancel',cancel.message);
    })     
}

export const ReadBanner = async (callback: (data: Array<Banner>) => unknown) => {
    const BannerList: Array<Banner> = []
    const BannerRef = ref(database, 'WineDatabase/Banner/');
    onValue(BannerRef, (snapshot) => {
        snapshot.forEach((child) => {
            const data = child.val()
            BannerList.push(data as Banner)
        })
        
        callback(BannerList)
    }, (cancel) => {
        console.log('cancel',cancel.message);
    })     
}


export const setContactUs = (uId: string, contactDetail: any, callback: () => unknown) => {
    const addContactRef = push(ref(database));
    const newId = ref(database, `WineDatabase/Contact/${uId}/${addContactRef.key}`);
    set(newId, {id: addContactRef.key, ...contactDetail}).then(() => callback())  
}

export const updateEmail = (uId: string, email: string, callback: () => unknown) => {
    const newId = ref(database, `WineDatabase/Account/${uId}/Email`);
    set(newId, email).then(() => callback())  
}

export const getPromocode = (code: string, callback: (percentage: PromoCode) => unknown, errorBack: () => unknown) => {
    const productRef = ref(database, `WineDatabase/PromoCode/${code}`);
    
    onValue(productRef, (snapshot) => {
        const data = snapshot.val();
        
        if(data){
            callback(data as PromoCode);
        }else{
            errorBack();    
        }
        
    }, (cancel) => {
        errorBack();
        console.log('cancel',cancel.message);
    })     
}

//-----------------------------------------------------------------------------------------------------

export const WriteCategory = async (categoryName: string) => {
    const addCategoryRef = push(ref(database));
    const newId = ref(database, `WineDatabase/Category/${addCategoryRef.key}`);
    return set(newId, {Name: categoryName, Id: addCategoryRef.key})
}

export const WritePromoCode = async (Code: string) => {
    const addPromoCodeRef = push(ref(database));
    const newId = ref(database, `WineDatabase/PromoCode/${addPromoCodeRef.key}`);
    return set(newId, {Code: Code, Id: addPromoCodeRef.key})
}

export const WriteImage = async (images: Array<any>, callback: (url: Array<String>) => unknown, UploadPercentag: (progress: number) => unknown) => {
    const imageUrls: Array<String> = []
    images.map((image) => {
        const storageRef = s_ref(defaultStorage, `Uploads/${image.name}`);
        const uploadTask = uploadBytesResumable(storageRef, image);
        uploadTask.on("state_changed",
          (snapshot) => {
            const progress =
              Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
              console.log('uplaouinsdf-----', progress);
              UploadPercentag(progress)
              
          },
          (error) => {
            alert(error);
          },
          async () => {
            await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                imageUrls.push(downloadURL);
                if (imageUrls.length === images.length){
                    callback(imageUrls);
                }
                console.log('uplaouinsdf-----', imageUrls);
            });
          }
        );
    });

    
}

export const ImageUpload = async (image: any, callback: (url: string) => unknown, UploadPercentag: (progress: number) => unknown) => {
    const randomName = Math.floor((Math.random() * 45) + 1);
    const storageRef = s_ref(defaultStorage, `Uploads/${randomName}`);
    const uploadTask = uploadBytesResumable(storageRef, image);
    uploadTask.on("state_changed",
      (snapshot) => {
        const progress =
          Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          UploadPercentag(progress)
          
      },
      (error) => {
        alert(error);
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            callback(downloadURL);
        });
      }
    );
}

export const RemoveImage = async (imageList: Array<string>, productid: string, callback: () => unknown) => {
    const productRef = ref(database, `WineDatabase/Products/${productid}/Images`);
    return set(productRef, imageList).then(() => callback());
}



export const ReadPromoCode = async (callback: (data: Array<PromoCode>) => unknown) => {
    const PromoCodeList: Array<PromoCode> = []
    const PromoCodeRef = ref(database, 'WineDatabase/PromoCode/');
    onValue(PromoCodeRef, (snapshot) => {
        snapshot.forEach((child) => {
            const data = child.val()
            PromoCodeList.push(data as PromoCode)
        })
        
        callback(PromoCodeList)
    }, (cancel) => {
        console.log('cancel',cancel.message);
    })     
}

export const UploadProduct = (product: any, file: Array<String>) => {
    
    const productObject = {Name: product.name, Images: file, Current_price: product.current_price, Original_price: product.original_price, Expire_date: product.expire_date, Category_id: product.category, Festival: product.festival, Stock: product.stock, Tax: 0, Description: product.description, Active: true}
    console.log(productObject);
    
    const addCategoryRef = push(ref(database));
    const newId = ref(database, `WineDatabase/Products/${addCategoryRef.key}`);
    return set(newId, {...productObject, Id: addCategoryRef.key})
}



export const UpdateProduct = async (product: any, id: string, callback: (data: string) => unknown) => {
    const productObject = {Id: id, Name: product.Name, Images: product.Images, Current_price: product.Current_price, Original_price: product.Original_price, Expire_date: product.Expire_date, Category_id: product.Category_id, Festival: product.Festival, Stock: product.Stock, Tax: product.Tax, Description: product.Description, Active: product.Active};
    
    const productRef = ref(database, `WineDatabase/Products/${id}/`);
    set(productRef, productObject).then(() => {
        callback('Update Successfully')
    });
}

export const deleteProduct = async (id: string | Array<string>, callback: () => unknown) => {
    console.log('deleteproeuct', typeof id);
    if ( typeof id === 'string' ){
     const productRef = ref(database, `WineDatabase/Products/${id}/`);
     remove(productRef).then(() => {
        callback();
     });
    } else {
        // eslint-disable-next-line array-callback-return
        (id as Array<string>).map(id => {
            const productRef = ref(database, `WineDatabase/Products/${id}/`);
            remove(productRef).then(() => {
                callback();
            });
        })
     
    }
}

export const ProductDetail = async (id: string, callback: (detail: Product) => unknown) => {
    const productRef = ref(database, `WineDatabase/Products/${id}`);
    onValue(productRef, (snapshot) => {
        
        callback(snapshot.val() as Product)
    }, (cancel) => {
        console.log('cancel',cancel.message);
    })     
}

export const deleteCategory = async (id: string, callBack: () => unknown) => {
    const cId = ref(database, `WineDatabase/Category/${id}`);
    remove(cId).then(() => {
        callBack();
    })
}
export const deletePromoCode = async (id: string, callBack: () => unknown) => {
    const cId = ref(database, `WineDatabase/PromoCode/${id}`);
    remove(cId).then(() => {
        callBack();
    })
}


export const userAccounts = async (callback: (data: Array<UserAccount>) => unknown) => {
    const AccountList: Array<UserAccount> = []
    const productRef = ref(database, 'WineDatabase/Account/');
    onValue(productRef, (snapshot) => {
        snapshot.forEach((child) => {
            const data = child.val()
            AccountList.push(data as UserAccount)
        })
        
        callback(AccountList)
    }, (cancel) => {
        console.log('cancel',cancel.message);
    })   
}

export const activeOrDeactiveUser = async (id: string, active: boolean, callback: () => unknown) => {
    const productRef = ref(database, `WineDatabase/Accounts/${id}/Active`);
    set(productRef, active).then(() => {
        callback();
    });
}

export const activeOrDeactiveProduct = async (id: string, active: boolean, callback: () => unknown) => {
    const productRef = ref(database, `WineDatabase/Products/${id}/Active`);
    set(productRef, active).then(() => {
        callback();
    });
}


export const getProductCount = async (callback: (count: number, cost: number) => unknown) => {
    let stockCount = 0;
    let cost= 0;
    await ReadProduct((product) => {
        product.map((data) => {
            stockCount += Number(data.Stock);
            cost += (Number(stockCount) * Number(data.Current_price));
        });
        callback(stockCount, cost);
    });
}

export const UserCount = async (callback: (count: number) => unknown) => {
    userAccounts((count) => callback(count.length))    
}