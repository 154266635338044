const PurchaseSuccess = () => {
    return(
        <div style={{height: 500, width: '100%', textAlign:'center', marginTop: 300}}>
            <h1>Payment Successfully</h1>
            <h3><a href="./">Continue Shopping</a></h3>
        </div>
    )
}


export default PurchaseSuccess;