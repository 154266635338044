import React, { Fragment, useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Link } from "react-router-dom";
import { RootState } from "../../Redux/Store";
import { useDispatch, useSelector } from "react-redux";
import { CartModel, Category, Product, Review, User } from "../../types";
import { AddProductReview, AddProductUserLike, AddToCart, GetCartProduct, isProductUserLike, ReadProductReview } from "../../Firebase";
import { addtoCart, setSelectProduct } from "../../Redux/Products/ProductManage";
import { setopenLoginModule } from "../../Redux/Authentication/Login";
import ZoomImage from "./ZoomImage";


const ProductDetail = () => {
  const dispatch = useDispatch()
  const userinfo: User = useSelector((state: RootState) => state.login.user);
  const product: Product = useSelector((state: RootState) => state.product.selectProduct);
  const products: Array<Product> = useSelector((state: RootState) => state.product.products); 
  const categories: Array<Category> = useSelector((state: RootState) => state.product.categorys);
  const MyCartDetail: Array<CartModel> = useSelector((state: RootState) => state.product.cart);


  const [reviewData, setReviewData] = useState(Array<Review>());
  const [rating, setRating] = useState(4);
  const [quantity, setQuantity] = useState(1);
  const [totalCart, setTotalCart] = useState(1);
  const [productLike, setProductLike] = useState(false);
  
  const [nav1, setNav1] = useState<Slider>();
  const [nav2, setNav2] = useState<Slider>();
  const ref = useRef();
  const [slider1, setSlider1] = useState<Slider | null>();
  const [slider2, setSlider2] = useState<Slider | null>();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [review, setReview] = useState(({
    name: "",
    email: "",
    review: "",
    body_review: "",
  }))
  
  useEffect(() => {
    let totalCartPrice = 0;
    MyCartDetail.map(item => {
      totalCartPrice+= Number(item.Product?.Current_price) * item.Quantity;
    });
    setTotalCart(totalCartPrice);
  }, [MyCartDetail])
  
  const handleChange = (e: any) => {
    setReview({
      ...review,
      [e.target.name]: e.target.value
    })
  }

  function openModal() {
    if(JSON.stringify(userinfo) === "{}"){
      dispatch(setopenLoginModule(true));
      return;
    }
    AddToCart(userinfo.uid, product.Id, quantity, (Number(product.Original_price) * quantity), () => {
      console.log('cartt', 'done');
      GetCartProduct(userinfo.uid, data => {
        dispatch(addtoCart(data));
      })
      setIsOpen(true);
    })
    
  }

  function closeModal() {
    setIsOpen(false);
  }

  const getCategory = (id: any) => {
    return categories.find(item => item.Id === id)?.Name
  }

  const selectProduct = (product: Product) => {
    dispatch(setSelectProduct(product));
  }


  useEffect(() => {
    if(slider1 !== null){
      setNav1(slider1);
    }
    if(slider2 !== null){
      setNav2(slider2);
    }
    
  }, [slider1, slider2]);

  const ReadReviewProduct = () => {
    ReadProductReview(product.Id, data => {
      setReviewData(data);
    })
  }

  const isLikeOrNot = () => {
    
    if(JSON.stringify(userinfo) === "{}"){
      dispatch(setopenLoginModule(true));
      return;
    }
    isProductUserLike(userinfo.uid, product.Id, isLike => {
      setProductLike(isLike);
    })
  }

  useEffect(() => {
    ReadReviewProduct();
    isLikeOrNot();
  },[])

  const likeProduct = () => {
    if(JSON.stringify(userinfo) === "{}"){
      dispatch(setopenLoginModule(true));
      return;
    }
    AddProductUserLike(userinfo.uid, product.Id, !productLike, () => {
      isLikeOrNot();
    })
  }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrow: true,
    navigtion: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };

  var Recent = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrow: true,
    navigtion: false,
  };

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    
  };
  const settingsThumbs = {
    slidesToShow: product.Images.length > 3 ? 4 : product.Images.length,
    slidesToScroll: 1,
    dots: true,
    centerMode: false,
    swipeToSlide: true,
    focusOnSelect: true,
  };

  return (
    <Fragment>
      <section className="product-detail-main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-7 col-md-6">
              <div className="bread-crumb">
                <a href="/" title="Back to the frontpage">
                  Home<i className="fa fa-angle-right" aria-hidden="true"></i>
                </a>
                <strong>{product.Name}</strong>
              </div>
            </div>
{/* 
            <div className="col-lg-5 col-md-6">
              <div className="arrows-product text-end">
                <div className="prev_prod">
                  <a href="/products/reprehenderit-east">
                    <i className="fa fa-angle-left" aria-hidden="true"></i>Prev
                  </a>
                  <div className="position-absolute img-prev">
                    <a href="/products/reprehenderit-east">
                      <img
                        data-src="//cdn.shopify.com/s/files/1/0606/0805/3436/products/product31_80x80.jpg?v=1642836928"
                        className=" lazyloaded"
                        src="//cdn.shopify.com/s/files/1/0606/0805/3436/products/product31_80x80.jpg?v=1642836928"
                      />
                    </a>
                    <div className="info-prod">
                      <a href="/products/reprehenderit-east">
                        Reprehenderit East
                      </a>
                      <p>$43.00</p>
                    </div>
                  </div>
                </div>
                <div className="next_prod">
                  <a href="/products/nourish-vitals">
                    Next<i className="fa fa-angle-right" aria-hidden="true"></i>
                  </a>
                  <div className=" img-next">
                    <a href="/products/nourish-vitals">
                      <img
                        data-src="//cdn.shopify.com/s/files/1/0606/0805/3436/products/product56_80x80.jpg?v=1642869904"
                        className=" lazyloaded"
                        src="//cdn.shopify.com/s/files/1/0606/0805/3436/products/product56_80x80.jpg?v=1642869904"
                      />
                    </a>
                    <div className="info-prod">
                      <a href="/products/nourish-vitals">Nourish Vitals</a>
                      <p>$17.00</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="col-lg-7 col-md-6">
              <div className="gallery-control">
                <Slider asNavFor={nav2} {...settingsMain} ref={(slider) =>setSlider1(slider)} >
                  {
                    product.Images.map((item, index) => {
                      console.log(item);
                      
                      return (
                        <div key={index} className="slick-slide">
                          {/* <img src={item} alt="test" height={'100%'} width={'100%'} style={{objectFit:'contain'}} /> */}
                          <ZoomImage item={item}/>
                        </div>
                      )
                    })
                  }
                </Slider>
                <Slider asNavFor={nav1} {...settingsThumbs} ref={(slider) => setSlider2(slider)}>
                  {
                    product.Images.map((item, index) => {
                      return (
                        <div key={index} className="slider-nav-main slick-slide">
                          <img src={item} alt="test" height={'100%'} width={'100%'} style={{objectFit:'contain'}} />
                        </div>
                      )
                    })
                  }
           
                </Slider>
              </div>
            </div>

            <div className="col-lg-5 col-md-6">
              <div className="detail-info">
                <h2 className="product-title text-start">{product.Name.toUpperCase()}
                <a className="p-2" onClick={likeProduct}>
                  {
                    productLike ? 
                    <svg id="Layer_1" height="30" width="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 107.39"><defs></defs><path className="cls-1" d="M60.83,17.18c8-8.35,13.62-15.57,26-17C110-2.46,131.27,21.26,119.57,44.61c-3.33,6.65-10.11,14.56-17.61,22.32-8.23,8.52-17.34,16.87-23.72,23.2l-17.4,17.26L46.46,93.55C29.16,76.89,1,55.92,0,29.94-.63,11.74,13.73.08,30.25.29c14.76.2,21,7.54,30.58,16.89Z" fill="red"/></svg>
                  :<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"height="30"width="30"id="svg2"version="1.1"fill="none" ><defs id="defs6"></defs><g transform="matrix(1.3333333,0,0,-1.3333333,0,400)"id="g10"><g transform="scale(0.1)" id="g12"><path id="path14"d="m 903,2424.4 c 157.9,0 306.4,-61.5 418.1,-173.1 l 134.8,-134.9 c 20.7,-20.6 48.1,-32 77.1,-32 29,0 56.4,11.4 77,32 l 133.7,133.7 c 111.7,111.6 259.9,173.1 417.5,173.1 156.91,0 305,-61.3 416.8,-172.5 111.2,-111.3 172.5,-259.5 172.5,-417.5 0.6,-157.3 -60.69,-305.5 -172.5,-417.4 L 1531.5,373.5 487.402,1417.6 c -111.601,111.7 -173.105,259.9 -173.105,417.5 0,158.1 61.199,306.1 172.5,416.8 111.308,111.2 259.101,172.5 416.203,172.5 z m 1829.7,-19.6 c 0,0 0,0 -0.1,0 -152.4,152.4 -355.1,236.3 -570.9,236.3 -215.7,0 -418.7,-84.1 -571.5,-236.9 l -56.9,-57 -58.2,58.2 c -153.1,153.1 -356.3,237.5 -572.1,237.5 -215.305,0 -417.902,-83.9 -570.305,-236.3 -153,-153 -236.8942,-356 -236.2966,-571.5 0,-215 84.4026,-417.8 237.4966,-571 L 1454.7,143.301 c 20.5,-20.403 48.41,-32.199 76.8,-32.199 28.7,0 56.7,11.5 76.7,31.597 L 2731.5,1261.8 c 152.7,152.7 236.8,355.7 236.8,571.4 0.7,216 -83,419 -235.6,571.6"fill="black"></path></g></g></svg>
                 
                }
                  </a>
                </h2>
                <div className="product-price text-start">
                  <ins className="enj-product-price engoj_price_main">
                    ${product.Current_price} USD
                  </ins>
                </div>
                <div className="wrap-rating">
                  <span
                    className="spr-badge"
                    id="spr_badge_7379529269436"
                    data-rating="0.0"
                  >
                    <span className="spr-starrating spr-badge-starrating">
                      <i className={`fa ${rating >= 1 ? 'fa-star' : 'fa-star-o'}`}></i>
                      <i className={`fa ${rating >= 2 ? 'fa-star' : 'fa-star-o'}`}></i>
                      <i className={`fa ${rating >= 3 ? 'fa-star' : 'fa-star-o'}`}></i>
                      <i className={`fa ${rating >= 4 ? 'fa-star' : 'fa-star-o'}`}></i>
                      <i className={`fa ${rating >= 5 ? 'fa-star' : 'fa-star-o'}`}></i>

                      <span className="spr-badge-caption">{reviewData.length} reviews</span>
                    </span>
                  </span>
                </div>
                <div className="pd_summary">
                  <p className=" mb-0">
                    {product.Description}
                  </p>
                </div>
                <p className="random_product">
                  <span id="random_prod">18</span> sold in last{" "}
                  <span id="random_hour">30</span> Hour
                </p>
                <div className="count-down">
                  <p className="text">
                    HURRY! ONLY <span id="random_sold_prod">{product.Stock}</span> LEFT IN
                    STOCK
                  </p>
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-striped progress-bar-animated"
                      style={{ width: "10%" }}
                    ></div>
                  </div>
                </div>
                <div className="extra-link">
                  <a href="javascript:void(0);" className="btn_delivery">
                    Delivery &amp; Return
                  </a>
                  <a href="javascript:void(0);" className="btn_question">
                    Ask a Question
                  </a>
                </div>
                <div className="btn-action detail-attr qty-cart">
                  <div className="">
                    <div className="js-qty">
                      <button
                        type="button"
                        className="qty_minus js-qty__adjust js-qty__adjust--minus icon-fallback-text"
                        data-id=""
                        data-qty="0"
                        onClick={() => setQuantity(quan => quan > 1 ? quan-1 : 1)}
                      >
                        <span className="fa fa-caret-down"></span>
                      </button>
                      <input
                        type="text"
                        className="js-qty__num"
                        value={quantity}
                        min="1"
                        data-id=""
                        aria-label="quantity"
                        pattern="[0-9]*"
                        name="quantity"
                        id="Quantity"
                      />
                      <button
                        type="button"
                        className="qty_plus js-qty__adjust js-qty__adjust--plus icon-fallback-text"
                        data-id=""
                        data-qty="11"
                        onClick={() => setQuantity(quan => quan+1)}
                      >
                        <span className="fa fa-caret-up"></span>
                      </button>
                    </div>
                  </div>

                  <button
                    className="shop-button enj-add-to-cart-btn engoj-btn-addtocart"
                    value="Add to Cart"
                    onClick={openModal}
                  >
                    Add to Cart
                  </button>
                </div>
                <button className="cmn-btn buy-it-now" onClick={openModal}>Buy it now</button>
                
                <ul className="product-meta-info">
                  <li>
                    <div className="item-product-meta-info product-category-info">
                      <label>Categories :</label>
                      <a href="#">{getCategory(product.Category_id)}</a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="shipping-detail p-70">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6 ">
              <div className="shipping-wrap">
                <div className="icon">
                  <i className="fas fa-plane"></i>
                </div>
                <div className="content">
                  <p className="title">WORLDWIDE SHIPPING</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 ">
              <div className="shipping-wrap">
                <div className="icon">
                  <i className="fas fa-plane"></i>
                </div>
                <div className="content">
                  <p className="title">FREE 60-DAY RETURNS</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 ">
              <div className="shipping-wrap">
                <div className="icon">
                  <i className="fas fa-plane"></i>
                </div>
                <div className="content">
                  <p className="title">24 MONTH WARRANTY</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 ">
              <div className="shipping-wrap">
                <div className="icon">
                  <i className="fas fa-plane"></i>
                </div>
                <div className="content">
                  <p className="title">100% SECRUE CHECKOUT</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="description">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link active"
                    data-bs-toggle="tab"
                    href="#description"
                    aria-selected="true"
                    role="tab"
                  >
                    Description
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    href="#additional-information"
                    aria-selected="false"
                    tabIndex={-1}
                    role="tab"
                  >
                    Additional Information
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    href="#review"
                    aria-selected="false"
                    tabIndex={-1}
                    role="tab"
                  >
                    Review
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div
                  id="description"
                  className="container-fluid tab-pane active"
                  role="tabpanel"
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="description-img">
                        <img src="./assets/images/img_des1.jpeg" alt="" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="description-text">
                        <h3>Wine Gift Set Includes:</h3>
                        <p>
                          The perfect way to gift this fantastic Champagne is in
                          our gorgeous, luxury gold gift box. Sure to WOW your
                          recipient!
                        </p>
                        <p>
                          The wine comes from the purest grape juice and it
                          alone allows Laurent-Perrier to craft 'La Cuvée', a
                          champagne of great finesse and a beautiful freshness
                          obtained after a long ageing process in our
                          cellars.Pale gold in color. Fine bubbles feed a
                          persistent mousse. A delicate nose with hints of fresh
                          citrus and white flowers
                        </p>
                        <div>
                          <img src="./assets/images/img_des2.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="des-info p-70 text-center">
                        <div className="text-center">
                          <h3>Product Details</h3>
                          <p>
                            The wine’s complexity is expressed in successive
                            notes like vine peach and white fruits notes. A
                            perfect balance between freshness and delicacy with
                            fruity flavors very present on the finish.This fresh
                            and pure wine is perfect for an apéritif. Its citrus
                            and white fruits notes and its remarkable balance
                            supported by a subtle effervescence, make it an
                            ideal accompaniment to poultry and the finest fish.
                          </p>
                        </div>
                        <img src="./assets/images/img_des3.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="additional-information"
                  className="container-fluid tab-pane"
                  role="tabpanel"
                >
                  <div className="row">
                    <div className="col-lg-8">
                      <div className="title_content">
                        <p className="more_info">More Infomation To You</p>
                        <h3 className="">Things you need to know</h3>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 content1">
                          <p className="info_1">
                            We use industry standard SSL encryption to protect
                            your details. Potentially sensitive information such
                            as your name, address and card details are encoded
                            so they can only be read on the secure server.
                          </p>
                          <ul className="px-0 list-unstyled">
                            <li>Safe Payments</li>
                            <li>Accept Credit Cart</li>
                            <li>Different Payment Method</li>
                            <li>Price Include VAT</li>
                            <li>Easy To Order</li>
                          </ul>
                        </div>
                        <div className="col-lg-6">
                          <div className="info2">
                            <h3>Express Delivery</h3>
                            <ul className="px-0 list-unstyled">
                              <li>Europe &amp; USA within 2-4 days</li>
                              <li>Rest of the world within 3-7 days</li>
                              <li>Selected locations</li>
                            </ul>
                          </div>
                          <div className="info2">
                            <h3>Need more information</h3>
                            <ul className="px-0 list-unstyled">
                              <li>
                                <a href="">Orders &amp; Shipping</a>
                              </li>
                              <li>
                                <a href="">Returns &amp; Refunds</a>
                              </li>
                              <li>
                                <a href="">Payments</a>
                              </li>
                              <li>
                                <a href="">Your Orders</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <img src="./assets/images/product56.png" alt="" />
                    </div>
                  </div>
                </div>
                <div
                  id="review"
                  className="container-fluid tab-pane"
                  role="tabpanel"
                >
                  <div className="row">
                    <div className="col-lg-12">
                      <h2 className="spr-header-title">Customer Reviews</h2>
                    </div>
                    <div className="spr-summary col-lg-12 ">
                        {
                            reviewData.map((item, index) => 
                              <div key={index}>
                                <div className="col-lg-12 review_name">
                                  <h6>{item.name}</h6>
                                </div>   
                                <p className="review_font">Reviewed on {item.date}</p>
                                <div className="review_item col-lg-8">
                                <div>
                                  <p className="review_font">{item.review}</p>
                                </div>   
                                <div>
                                  <p className="review_font">{item.body_review}</p>
                                </div>
                                </div>   
                              </div>   
                             )
                          }
                        </div>
                      
                    <div className="col-lg-12">
                      
                      <div className="spr-content">
                        <div className="spr-form">
                          <h3 className="spr-form-title">Write a review</h3>
                          <form onSubmit={e => {
                            // e.preventDefault()
                            AddProductReview(review, product.Id, () => {
                              setReview(({
                                name: "",
                                email: "",
                                review: "",
                                body_review: "",
                              }))

                              ReadReviewProduct();
                            })
                            
                          }}>
                            <div className="form-group">
                              <label htmlFor="Name">Name</label>
                              <input
                                type="text"
                                placeholder="Enter your name"
                                id="name"
                                name="name"
                                value={review.name}
                                onChange={handleChange}
                                className="form-control"
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="email">email</label>
                              <input
                                type="text"
                                placeholder="john.smith@example.com"
                                id="email"
                                name="email"
                                value={review.email}
                                onChange={handleChange}
                                className="form-control"
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="review">review</label>
                              <input
                                type="text"
                                id="review"
                                name="review"
                                value={review.review}
                                onChange={handleChange}
                                placeholder="Give your review a title"
                                className="form-control"
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="body-review">
                                Body of Review (1500)
                              </label>
                              <textarea
                                id="body_review"
                                name="body_review"
                                rows={10}
                                value={review.body_review}
                                onChange={handleChange}
                                className="form-control"
                                required
                                placeholder="Write your comments here"
                              ></textarea>
                            </div>
                            <div className="text-end">
                              <input
                                type="submit"
                                className="cmn-btn border-0"
                                value="Submit Review"
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="related-product p-70">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="title2 text-center">
                <h3>RELATED PRODUCTS</h3>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="">
                <Slider {...settings}>
                {
                  products.map((product, index) => 
                    <div key={index} onClick={() => selectProduct(product)}>
                    <div className="best-seller-wrap text-center">
                    <a href="#">
                      <div className="seller-img-product position-relative ">
                    
                          <img
                            src={product.Images[0]}
                            alt="product1"
                          
                          />
                     
                        <ul className="best-seller-products p-0 m-0"></ul>
                      </div>
                      <div>
                        <h4>
                          <a href="#">{product.Name}</a>{" "}
                        </h4>
                        <p>
                          <s className="price-old">${product.Original_price}</s>
                          <span className="price">${product.Current_price}</span>
                        </p>
                      </div>
                  </a>
                    </div>
                  </div>
                  )
                }

                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        open={modalIsOpen}
        onClose={closeModal}
      >
        <div className="row">
          <div className="col-lg-6 product-image text-center">
            <p className="success-message">
              <i className="fas fa-check"></i> Added to cart successfully!
            </p>
            <div>
              <img src={product.Images[0]} alt="" />
            </div>
            <div className="product-info text-center">
              <p className="product-name">{product.Name}</p>
              <p className="text-uppercase">
                Price : <span className="product-price">${product.Original_price}.00</span>
              </p>
              <p>
                QTY : <span className="product-qty">{quantity}</span>
              </p>
              <p>
                {" "}
                CART TOTALS : <span className="product-total">${(Number(product.Original_price) * quantity)}.00</span>
              </p>
            </div>
          </div>
          <div className="col-lg-6 more_info text-center">
            <p>
              {" "}
              There are <span className="product-item-count">{MyCartDetail.length}</span> items in
              your cart{" "}
            </p>
            <p className="total_price">
              {" "}
              CART TOTALS : <span className="product-total-cart">${totalCart}.00</span>
            </p>
            <div className="actions">
              <button className="continue-shopping shop-button">
                Continue shopping
              </button>
              <Link to={"/cart"} className="shop-button go_cart">
                Go to cart
              </Link>
              <div className="product-cart__condition d-flex justify-content-center text-center align-items-center mb-3">
                <input type="checkbox" className="engoj-agree" />
                <label
                  htmlFor="product-cart__agree-product-template"
                  className="mb-0"
                >
                  Agree with term and conditional.
                </label>
              </div>
              <Link
                to={"/checkout"}
                className="checkout-button shop-button go_cart"
              >
                Proceed to checkout
              </Link>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default ProductDetail;
